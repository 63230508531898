import SideBar from "@/components/Sidebar";
import { Box } from "@chakra-ui/react";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { useUserSession } from "./hooks/useUserSession";
import { useEffect } from "react";
import Footer from "./ui/Footer";

const Layout = () => {
  const { user, isReady } = useUserSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isReady) return;
    if (!user) {
      navigate("/login");
    } else {
      const date = user?.createdAt.toDate();

      if (
        !user?.isRegistrationComplete &&
        (!date || date < new Date("2024-05-07"))
      ) {
        navigate("/complete-profile/status", {
          replace: true,
        });
      }
    }
  }, [user, isReady, navigate]);

  return (
    <>
      <Box
        width={"100%"}
        minH={"100vh"}
        background={"#E6F2F0"}
        display={"flex"}
        flexDir={"row"}
        alignItems={"stretch"}
        position={"relative"}
      >
        <SideBar />
        <Box
          display={"flex"}
          alignItems={"stretch"}
          justifyContent={"flex-start"}
          width={"100%"}
          minH={"100vh"}
          overflow={"hidden"}
        >
          <Outlet />
        </Box>
      </Box>
      <Footer />
      <ScrollRestoration />
    </>
  );
};

export default Layout;
