import { initializeApp } from "firebase/app";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAqnXIDUWKXU-nz6h4A4PSCUHEPQVrEEoA",
  authDomain: "paperdoc-64504.firebaseapp.com",
  projectId: "paperdoc-64504",
  storageBucket: "paperdoc-64504.appspot.com",
  messagingSenderId: "200802767707",
  appId: "1:200802767707:web:24e5769b4a30bdc476c07e",
  measurementId: "G-W79W1TL0SP",
};
const firebaseIaConfig = {
  apiKey: "AIzaSyBzkSkyeLBAchbuL0-AQE-FBw1Urq15p6g",

  authDomain: "paperdoc-ia-dev.firebaseapp.com",

  projectId: "paperdoc-ia-dev",

  storageBucket: "paperdoc-ia-dev.appspot.com",

  messagingSenderId: "129406116948",

  appId: "1:129406116948:web:3aaffdb5e6cfc5409379c1",

  measurementId: "G-XZYT973KTB",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

const appIa = initializeApp(firebaseIaConfig, "appIa");
const dbIa = getFirestore(appIa);
const authIa = getAuth(appIa);
const storageIa = getStorage(appIa);
const functions = getFunctions(appIa, "europe-west1");
const analytics = getAnalytics(app);

// eslint-disable-next-line no-constant-condition
if (false) {
  console.log("Emulator mode", process.env.NODE_ENV);
  connectFirestoreEmulator(dbIa, "127.0.0.1", 8080);
  connectStorageEmulator(storageIa, "127.O.O.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectAuthEmulator(authIa, "http://localhost:9099");
}

export { db, auth, dbIa, authIa, storageIa, functions, analytics };
