import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  CardFooter,
  useDisclosure,
  LinkBox,
  LinkOverlay,
  IconButton,
  Icon,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import Tldr from "./Tldr";
import { PaperResponse, SearchResponseItem } from "@/lib/SemanticScholarApi";
import slugify from "slugify";

import useArchieData from "@/hooks/useArchieData";
import SearchArticleAnswer from "./SearchArticleAnswer";
import { useCollections } from "@/hooks/useCollections";
import AddElementToCollectionModal from "@/components/collections/AddElementToCollectionModal";
import {
  MdCollectionsBookmark,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";

const SearchArticle = (
  props: (SearchResponseItem | PaperResponse) & {
    hideArchieAnswer?: boolean;
  }
) => {
  const {
    title,
    tldr,
    journal,
    authors,
    citationCount,
    publicationDate,
    year,
    corpusId,
    hideArchieAnswer = false,
  } = props;

  const { displayed, archieYO } = useArchieData((state) => state);
  const { collections } = useCollections();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const slug = slugify(title, {
    lower: true,
    strict: true,
  });

  return (
    <>
      <LinkBox
        as={Card}
        id={`paper-${corpusId}`}
        width={"100%"}
        bgColor={"#fff"}
        borderRadius={28}
        p={2}
        minH={200}
      >
        <CardHeader
          pb={0}
          display={"flex"}
          gap={4}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          w={"100%"}
        >
          <LinkOverlay as={Link} to={`/article/${slug}/${corpusId}`}>
            <Heading
              fontFamily={"Be Vietnam Pro"}
              fontWeight={900}
              as={"h2"}
              size={"md"}
            >
              {title}
            </Heading>
          </LinkOverlay>
          {displayed.aiSearch &&
            displayed.insights &&
            archieYO &&
            !hideArchieAnswer && <SearchArticleAnswer corpusId={corpusId} />}
        </CardHeader>
        <CardBody
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          {tldr && (
            <Text>
              <Tldr />
              {tldr.text}
            </Text>
          )}
        </CardBody>
        <CardFooter
          pt={0}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Text fontSize={12} fontStyle={"italic"}>
            {publicationDate
              ? new Date(publicationDate).toLocaleDateString()
              : year}{" "}
            | {journal?.name} |{" "}
            {authors
              .slice(0, 1)
              .map((author) => author.name)
              .join(", ")}{" "}
            et al. | {citationCount} citations
          </Text>
          <IconButton
            onClick={openModal}
            variant={"ghost"}
            aria-label="Add to collection"
            icon={
              <Icon
                as={
                  collections.find((c) =>
                    c.papers.includes(corpusId.toString())
                  )
                    ? MdCollectionsBookmark
                    : MdOutlineCollectionsBookmark
                }
                w={6}
                h={6}
              />
            }
          />
        </CardFooter>
      </LinkBox>
      <AddElementToCollectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        elementId={corpusId.toString()}
        elementType="paper"
      />
    </>
  );
};

export default SearchArticle;
