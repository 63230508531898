import { loadStripe } from "@stripe/stripe-js";

const initializeStripe = async () => {
  const stripe = await loadStripe(
    "pk_live_51OlUs1CsQCsoHW1dLQuSatoTJI9jpCQxSFnxZMoQ9HUn2qazj4k5SlERN3my3Xa6uAcQyky3hn477VKnfg6bT4zD00mClGZcYJ"
  );
  return stripe;
};

export default initializeStripe;
