import Container from "@/ui/Container";
import MainCard from "@/ui/MainCard";
import { Button, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Building = () => {
  const { t } = useTranslation();
  return (
    <Container hasSearch>
      <MainCard
        bgColor={"#fff"}
        mt={8}
        display={"flex"}
        flexDir={"column"}
        gap={10}
        alignItems={"center"}
        justifyContent={"center"}
        h={"100%"}
      >
        <Heading textAlign={"center"} as={"h1"} size={"lg"} maxW={"80%"}>
          {t("building.title")}
        </Heading>

        <Button colorScheme="brand" as={Link} to={"/"}>
          Retour à l'accueil
        </Button>
      </MainCard>
    </Container>
  );
};

export default Building;
