import {
  Heading,
  Tag,
  HStack,
  SkeletonText,
  Box,
  VStack,
  Text,
  IconButton,
  Checkbox,
  Flex,
  UnorderedList,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import useArchieData from "@/hooks/useArchieData";
import { useCallback, useEffect, useState } from "react";
import { CheckIcon, CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { IoFilterOutline } from "react-icons/io5";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";
import {
  getArchieYOAnswers,
  getArchieYOQuestion,
} from "@/lib/cloudFunctions/ArchieYO";

const ArchieYO = () => {
  const { t } = useTranslation();
  const {
    archieYO,

    setArchieData,
    toggleFilterByAnswer,
    filterByAnswer,
    setSelectedAnswers,
    selectedAnswer,
    token,
    setRefs,
  } = useArchieData((state) => state);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [resultsLoading, setResultsLoading] = useState(false);

  const getAnswers = useCallback(
    async (data: typeof archieYO) => {
      if (!data) {
        return;
      }
      setResultsLoading(true);
      try {
        await getArchieYOAnswers({
          token,
          question: data.question,
        });
      } catch (error) {
        setError(true);
      }
      setResultsLoading(false);
    },
    [token]
  );

  const fetchArchieYO = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const res = await getArchieYOQuestion({
        token,
      });
      if (res.data.is_yes_no) {
        setResultsLoading(true);

        getAnswers({
          answers: {},
          answers_weight: {
            yes: 0,
            no: 0,
            potentially: 0,
          },
          papers: [],
          is_yes_no: res.data.is_yes_no,
          question: res.data.question,
          answer_loaded: false,
        });
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  }, [token, getAnswers]);
  useEffect(() => {
    if (token) {
      const unsub = onSnapshot(doc(dbIa, "archie_yo", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          const answersLoaded = !!data.answers;
          setArchieData({
            archieYO: {
              answers: answersLoaded ? data.answers : {},
              answers_weight: answersLoaded
                ? data.answers_weight
                : {
                    yes: 0,
                    no: 0,
                    potentially: 0,
                  },
              papers: answersLoaded ? data.papers : [],
              is_yes_no: data.is_yes_no,
              answer_loaded: answersLoaded,
              question: data.question,
            },
          });
          if (answersLoaded) {
            setRefs(new Set(data.papers));
          }
          setLoading(false);
        } else {
          fetchArchieYO();
        }
      });

      return () => unsub();
    }
  }, [fetchArchieYO, token, setArchieData, setRefs]);

  if (loading) return <SkeletonText noOfLines={2} spacing={4} minW={"400px"} />;

  if (error) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={4}
        marginY={"auto"}
        minW={"400px"}
      >
        <Text> {t("archie.error.title")}</Text>
        <UnorderedList>
          <ListItem>{t("archie.error.1")}</ListItem>
          <ListItem>{t("archie.error.2")}</ListItem>
          <ListItem>{t("archie.error.3")}</ListItem>
        </UnorderedList>

        <Text>{t("archie.error.final")}</Text>
      </Box>
    );
  }
  if (!archieYO) return null;
  return (
    <Box minW={["auto", "auto", "auto", "auto", "400px"]}>
      {archieYO.papers.length > 0 && (
        <Tag mt={1}>
          {t("archie.papersAnalysed", {
            count: archieYO.papers.length,
          })}
        </Tag>
      )}

      <HStack mt={4} gap={4} justifyContent={"space-between"}>
        <Heading
          as={"h3"}
          size={"sm"}
          textAlign={"left"}
          sx={{
            textWrap: "balance",
          }}
        >
          {archieYO.question}
        </Heading>

        <IconButton
          aria-label="Filter by answer"
          variant={"ghost"}
          icon={<IoFilterOutline size={26} />}
          onClick={toggleFilterByAnswer}
        />
      </HStack>

      <Flex gap={2} flexWrap={"wrap"}>
        {resultsLoading ? (
          <>
            <SkeletonText noOfLines={2} spacing={4} w={"100%"} mt={4} />
          </>
        ) : (
          <>
            {archieYO.answer_loaded ? (
              <>
                <VStack mt={4} spacing={4} align={"stretch"}>
                  <HStack spacing={2} justify={"space-between"}>
                    <HStack spacing={2}>
                      {filterByAnswer ? (
                        <Checkbox
                          isChecked={selectedAnswer.yes}
                          onChange={() =>
                            setSelectedAnswers({
                              ...selectedAnswer,
                              yes: !selectedAnswer.yes,
                            })
                          }
                        />
                      ) : (
                        <CheckIcon color={"green.500"} />
                      )}

                      <Text width={"18ch"} fontWeight={"bold"}>
                        {t("archie.yes_no.yes")} - {archieYO.answers_weight.yes}
                        %
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack spacing={2} justify={"space-between"}>
                    <HStack spacing={2}>
                      {filterByAnswer ? (
                        <Checkbox
                          isChecked={selectedAnswer.potentially}
                          onChange={() =>
                            setSelectedAnswers({
                              ...selectedAnswer,
                              potentially: !selectedAnswer.potentially,
                            })
                          }
                        />
                      ) : (
                        <MinusIcon color={"yellow.500"} />
                      )}
                      <Text width={"18ch"} fontWeight={"bold"}>
                        {t("archie.yes_no.potentially")} -{" "}
                        {archieYO.answers_weight.potentially}%
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack spacing={2} justify={"space-between"}>
                    <HStack spacing={2}>
                      {filterByAnswer ? (
                        <Checkbox
                          isChecked={selectedAnswer.no}
                          onChange={() =>
                            setSelectedAnswers({
                              ...selectedAnswer,
                              no: !selectedAnswer.no,
                            })
                          }
                        />
                      ) : (
                        <CloseIcon color={"red.500"} />
                      )}

                      <Text width={"18ch"} fontWeight={"bold"}>
                        {t("archie.yes_no.no")} - {archieYO.answers_weight.no}%
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
                <ResponsiveContainer height={150} width={150}>
                  <PieChart>
                    <Pie
                      data={[
                        {
                          name: t("archie.yes_no.yes"),
                          value: archieYO.answers_weight.yes,
                        },
                        {
                          name: t("archie.yes_no.potentially"),
                          value: archieYO.answers_weight.potentially,
                        },
                        {
                          name: t("archie.yes_no.no"),
                          value: archieYO.answers_weight.no,
                        },
                      ]}
                      dataKey="value"
                      nameKey="name"
                      paddingAngle={5}
                      innerRadius={45}
                      outerRadius={65}
                      isAnimationActive
                      fill="#8884d8"
                    >
                      <Cell fill={"#38A169"} />
                      <Cell fill={"#D69E2E"} />
                      <Cell fill={"#E53E3E"} />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </>
            ) : (
              <Box mt={4} display={"flex"} flexDirection={"column"} gap={2}>
                <Text>{t("archie.yes_no.not_loaded")}</Text>
                <Text>
                  {t("archie.yes_no.should_load", {
                    question: archieYO.question,
                  })}
                </Text>
                <Button
                  alignSelf="center"
                  colorScheme="brand"
                  onClick={() => getAnswers(archieYO)}
                >
                  {t("archie.yes_no.load")}
                </Button>
              </Box>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ArchieYO;
