import { Tag, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Tldr = () => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("tldr")} aria-label="A tooltip: Too Long; Didn't Read">
      <Tag mr={2}>TLDR</Tag>
    </Tooltip>
  );
};

export default Tldr;
