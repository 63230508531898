import {
  collection,
  getDocs,
  limit,
  orderBy,
  //orderBy,
  query,
  where,
} from "firebase/firestore";
import { authIa, dbIa } from "../firebase";

import semanticScholarApi, { PaperResponse } from "../SemanticScholarApi";
import { defer } from "react-router-dom";

const homeLoader = async () => {
  await authIa.authStateReady();
  let user = authIa.currentUser;
  if (!user) {
    await new Promise((resolve) => {
      setTimeout(() => {
        user = authIa.currentUser;
        resolve(user);
      }, 500);
    });
  }

  const articles = new Promise<PaperResponse[]>((resolve) => {
    getDocs(
      query(
        collection(dbIa, "users", user?.email as string, "history"),
        orderBy("readAt", "desc"),
        limit(100)
      )
    ).then(async ({ docs: historyDocs }) => {
      if (historyDocs.length > 0) {
        const ids = historyDocs.map((doc) => {
          if (/^\d+$/.test(doc.id)) {
            return `CorpusId:${doc.id}`;
          }
          return doc.id;
        });

        try {
          const recommended = await semanticScholarApi.recommandations.post(
            "/papers",
            {
              positivePaperIds: ids,
              negativePaperIds: [],
            },
            {
              params: {
                fields:
                  "title,authors,journal,externalIds,year,corpusId,citationCount,isOpenAccess",
              },
            }
          );
          resolve(recommended.data.recommendedPapers);
        } catch (error) {
          const { docs } = await getDocs(
            query(
              collection(dbIa, "papers_ss"),

              where("isActive", "==", true),
              orderBy("publicationdate", "desc"),
              limit(10)
            )
          );

          const res = await semanticScholarApi.search.post(
            "/paper/batch",
            {
              ids: docs.map((doc) => {
                if (/^\d+$/.test(doc.id)) {
                  return `CorpusId:${doc.id}`;
                }
                return doc.id;
              }),
            },
            {
              params: {
                fields:
                  "title,authors,journal,externalIds,tldr,year,publicationDate,isOpenAccess,citationCount",
              },
            }
          );
          resolve(
            res.data.filter((article: PaperResponse | null) => !!article)
          );
        }
      } else {
        const { docs } = await getDocs(
          query(
            collection(dbIa, "papers_ss"),

            where("isActive", "==", true),
            orderBy("publicationdate", "desc"),
            limit(15)
          )
        );
        const ids = docs.map((doc) => `CorpusId:${doc.id}`);
        const res = await semanticScholarApi.search.post(
          "/paper/batch",
          {
            ids: ids,
          },
          {
            params: {
              fields: "title,authors,journal,externalIds,tldr,year",
            },
          }
        );
        resolve(res.data.filter((article: PaperResponse | null) => !!article));
      }
    });
  });

  return defer({ articles });
};

export default homeLoader;
