import useRegistrationForm from "@/hooks/useRegistrationForm";

import {
  Button,
  Input,
  Box,
  Progress,
  FormControl,
  FormLabel,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type FormData = {
  firstName: string;
  lastName: string;
  rpps: string;
  job: string;
};

const ValidateRpps = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rppsData, nextStep, setRppsData } = useRegistrationForm();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: rppsData as FormData,
  });

  const onSubmit = handleSubmit(async (data) => {
    setRppsData(data);
    nextStep();
    navigate("/register/terms");
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Text mb={-6} textAlign={"center"}>
        {t("auth.register.winCoins")}
      </Text>
      <Progress value={90} />

      <Heading mb={8} color={"#fff"}>
        {t("auth.register.validateRpps")}
      </Heading>
      <FormControl>
        <FormLabel>{t("auth.login.firstName")}</FormLabel>
        <Input
          disabled
          type="text"
          placeholder={t("auth.login.firstName")}
          variant={"auth"}
          {...register("firstName")}
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t("auth.login.lastName")}</FormLabel>

        <Input
          disabled
          type="text"
          placeholder={t("auth.login.lastName")}
          variant={"auth"}
          {...register("lastName")}
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t("auth.register.job")}</FormLabel>
        <Input
          type="text"
          placeholder={t("auth.register.job")}
          variant={"auth"}
          {...register("job")}
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel>{t("auth.register.rpps")}</FormLabel>
        <Input
          type="text"
          placeholder={t("auth.register.rpps")}
          variant={"auth"}
          {...register("rpps")}
          required
        />
      </FormControl>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.itsMe")}
      </Button>
      <Button variant={"outlined"} onClick={() => navigate("/register/terms")}>
        {t("auth.register.notMe")}
      </Button>
    </Box>
  );
};

export default ValidateRpps;
