import useArchieData from "@/hooks/useArchieData";
import { Text, HStack } from "@chakra-ui/react";
import { useRef } from "react";
import CopyContent from "../CopyContent";

type ArchieQAnswerProps = {
  answer: Array<string | JSX.Element> | null;
  question: string;
};

const ArchieQAnswer = ({ answer, question }: ArchieQAnswerProps) => {
  const { archieQ } = useArchieData((state) => state);
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <HStack alignItems={"flex-start"}>
      <Text ref={contentRef}>{answer}</Text>
      <CopyContent
        contentRef={contentRef}
        refs={archieQ!.papers}
        textStart={`${question} \n`}
      />
    </HStack>
  );
};

export default ArchieQAnswer;
