import { useUserSession } from "@/hooks/useUserSession";
import {
  DocumentReference,
  addDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { StorageError, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dbIa, functions, storageIa } from "../firebase";
import { httpsCallable } from "firebase/functions";

type onSummaryGenerated = (fullSummaryRef: DocumentReference) => Promise<void>;

const useSummaryGeneration = (onSummaryGenerated: onSummaryGenerated) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<DocumentReference | null>(null);
  const [error, setError] = useState("");
  const [summaryDoc, setSummaryDoc] = useState<{
    id: string;
    slug: string;
  } | null>(null);
  const { user } = useUserSession();

  useEffect(() => {
    if (document) {
      const unsubscribe = onSnapshot(document, (snapshot) => {
        if (snapshot.exists() === false) {
          return;
        }
        if (snapshot.data().status === "processing") {
          setCurrentStep(1);
        }
        if (snapshot.data().status === "done") {
          currentStep == 1 && setCurrentStep(2);
          onSummaryGenerated(snapshot.data().full_summary).then(() => {
            setTimeout(() => {
              setCurrentStep(3);
            }, 500);
          });
        }
        if (snapshot.data().status === "error") {
          setError((prev) => prev || t("summarize.error"));
        }
      });
      if (currentStep == 3 && document) {
        unsubscribe();
      }
      return () => unsubscribe();
    }
  }, [document, t, onSummaryGenerated, currentStep]);

  useEffect(() => {
    if (error) {
      setCurrentStep(0);
      setLoading(false);
      setDocument(null);
    }
  }, [error]);

  const reset = () => {
    setCurrentStep(0);
    setLoading(false);
    setDocument(null);
    setError("");
    setSummaryDoc(null);
  };
  const startUnknownSummary = async (file: File) => {
    if (user?.currentPlan != "premium" && (user?.paperCoins || 0) < 40)
      return setError(t("summarize.notEnoughCoins"));
    setLoading(true);
    try {
      setError("");
      const fileName = file.name.replaceAll(" ", "_");
      const storageRef = ref(storageIa, `files/${fileName}`);
      await uploadBytes(storageRef, file);
      const docRef = await addDoc(collection(dbIa, "users_articles"), {
        status: "pending",
        file: fileName,
        createdAt: new Date(),
        paperPath: `files/${fileName}`,
        user: user?.id || null,
        type: "unknown",
      });

      setDocument(docRef);

      const requestSummary = httpsCallable(functions, "request_summary");
      requestSummary({ id: docRef.id }).catch((error) => {
        console.error(error.code);
        if (error.code === "functions/internal") {
          setError(t("summarize.internalError"));
        } else if (error.code !== "functions/deadline-exceeded") {
          setError(t("summarize.fileError"));
        }
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      if (error instanceof StorageError) {
        setError(t("summarize.fileError"));
      } else {
        setError(t("summarize.internalError"));
      }

      setLoading(false);
    }
  };

  const startOpenSummary = async (corpusId: string) => {
    setError("");
    if (user?.currentPlan != "premium" && (user?.paperCoins || 0) < 40)
      return setError(t("summarize.notEnoughCoins"));

    setLoading(true);
    const docRef = await addDoc(collection(dbIa, "users_articles"), {
      status: "pending",
      createdAt: new Date(),
      user: user?.id || null,
      type: "open",
      corpusId,
    });
    setDocument(docRef);

    const requestOpenSummary = httpsCallable(functions, "request_open_summary");

    requestOpenSummary({ id: docRef.id }).catch((error) => {
      console.error(error.code);
      if (error.code === "functions/internal") {
        setError(t("summarize.internalError"));
      } else if (error.code === "functions/permission-denied") {
        setError(t("summarize.permissionDenied"));
      } else if (error.code !== "functions/deadline-exceeded") {
        setError(t("summarize.fileError"));
      }

      setLoading(false);
    });
  };

  return {
    currentStep,
    setCurrentStep,
    loading,
    setLoading,
    document,
    setDocument,
    error,
    setError,
    summaryDoc,
    setSummaryDoc,
    reset,
    startUnknownSummary,
    startOpenSummary,
  };
};

export default useSummaryGeneration;
