import { Button, Icon, IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoHelpCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const HelpButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        as={Link}
        to={"/help"}
        hideBelow={"lg"}
        variant="ghost"
        leftIcon={<Icon as={IoHelpCircleOutline} w={6} h={6} />}
        color={"gray"}
      >
        {t("help.button")}
      </Button>
      <IconButton
        as={Link}
        to={"/help"}
        hideFrom={"lg"}
        aria-label="Help"
        icon={<Icon as={IoHelpCircleOutline} w={6} h={6} />}
        color={"gray"}
      />
    </>
  );
};

export default HelpButton;
