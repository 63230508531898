import { Box, BoxProps } from "@chakra-ui/react";

type MainCardProps = BoxProps;

const MainCard = (props: MainCardProps) => {
  const { children, ...rest } = props;
  return (
    <Box p={4} pb={0} borderRadius={32} {...rest}>
      {children}
    </Box>
  );
};

export default MainCard;
