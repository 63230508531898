//import { collection, getDocs, query, where } from "firebase/firestore";
import { LoaderFunctionArgs } from "react-router-dom";
//import { authIa, dbIa } from "../firebase";

const storageLoader = async ({
  params,
}: {
  params: LoaderFunctionArgs["params"];
}) => {
  console.log(params);
  /*const path = params["*"] as string;
  const user = authIa.currentUser;
  if (!user) return redirect("/login");
  const collectionRef = collection(
    dbIa,
    "users",
    user.email as string,
    "storage"
  );
  const q1 = getDocs(
    query(
      collectionRef,
      where("path", "==", path || "/"),
      where("type", "==", "folder")
    )
  );
  const q2 = getDocs(
    query(
      collectionRef,
      where("path", "==", path || "/"),
      where("type", "in", ["file", "link"])
    )
  );

  //Map on path segements to get name fos folders
  const pathSegments = path.split("/").filter((s) => s);
 */

  return null;
};

export default storageLoader;
