import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  CardFooter,
  LinkBox,
  LinkOverlay,
  Icon,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Tldr from "./Tldr";
import semanticScholarApi, { PaperResponse } from "@/lib/SemanticScholarApi";
import slugify from "slugify";
import { useEffect, useState } from "react";
import {
  MdCollectionsBookmark,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
import AddElementToCollectionModal from "@/components/collections/AddElementToCollectionModal";
import { useCollections } from "@/hooks/useCollections";

const HomeArticle = (props: PaperResponse) => {
  const {
    title,
    tldr,

    journal,
    paperId,
    authors,
    year,
    publicationDate,
    citationCount,
    corpusId,
  } = props;
  const slug = slugify(title, {
    lower: true,
    strict: true,
  });
  const [tldrText, setTldrText] = useState<string | undefined>(tldr?.text);
  const { collections } = useCollections();
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  useEffect(() => {
    if (!tldr) {
      (async () => {
        const tlderRes = await semanticScholarApi.search.get(
          `/paper/${paperId}`,
          {
            params: {
              fields: "tldr",
            },
          }
        );
        setTldrText(tlderRes.data.tldr?.text);
      })();
    }
  }, [tldr, paperId]);

  return (
    <>
      <LinkBox as={Card} bgColor={"#fff"} borderRadius={28} p={4}>
        <CardHeader pb={0}>
          <LinkOverlay as={Link} to={`/article/${slug}/${corpusId}`}>
            <Heading
              fontFamily={"Be Vietnam Pro"}
              fontWeight={900}
              as={"h2"}
              size={"md"}
              mb={2}
            >
              {title}
            </Heading>
          </LinkOverlay>
        </CardHeader>
        <CardBody py={4}>
          {tldrText && (
            <Text>
              <Tldr />
              {tldrText}
            </Text>
          )}
        </CardBody>
        <CardFooter
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          pt={0}
        >
          <Text fontSize={12} fontStyle={"italic"}>
            {publicationDate
              ? new Date(publicationDate).toLocaleDateString()
              : year}{" "}
            | {journal?.name} |{" "}
            {authors
              .slice(0, 1)
              .map((author) => author.name)
              .join(", ")}{" "}
            et al. | {citationCount} citations
          </Text>
          <IconButton
            onClick={openModal}
            variant={"ghost"}
            aria-label="Add to collection"
            icon={
              <Icon
                as={
                  collections.find((c) =>
                    c.papers.includes(corpusId.toString())
                  )
                    ? MdCollectionsBookmark
                    : MdOutlineCollectionsBookmark
                }
                w={6}
                h={6}
              />
            }
          />
        </CardFooter>
      </LinkBox>
      <AddElementToCollectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        elementId={corpusId.toString()}
        elementType="paper"
      />
    </>
  );
};

export default HomeArticle;
