import useArchieData from "@/hooks/useArchieData";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const answerColors = {
  yes: "green.500",
  no: "red.500",
  potentially: "yellow.500",
  unknown: "gray.500",
  irrelevant_paper: "gray.500",
};

const SearchArticleAnswer = ({ corpusId }: { corpusId: string | number }) => {
  const id = typeof corpusId === "string" ? corpusId : corpusId.toString();
  const { archieYO } = useArchieData((state) => state);
  const { t } = useTranslation();

  const getPaper = () => {
    if (!archieYO)
      return {
        answer: "unknown" as keyof typeof answerColors,
        justification: "",
      };

    const paper = archieYO.answers[id];

    if (!paper || paper.answer === "irrelevant_paper")
      return {
        answer: "unknown" as keyof typeof answerColors,
        justification: "",
      };

    return paper;
  };

  const paper = getPaper();

  if (paper.answer === "unknown" || paper.answer === "irrelevant_paper")
    return null;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Tag
          zIndex={2}
          minW={"fit-content"}
          color={"white"}
          bgColor={answerColors[paper.answer]}
        >
          {t(`archie.yes_no.${paper.answer}`)}
        </Tag>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text>{archieYO?.question}</Text>
          <Text fontWeight={"bold"}>{paper.justification}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchArticleAnswer;
