import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type Actions = {
  setFirstName: (name: string) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setLastName: (name: string) => void;
  setDomain: (domain: string) => void;
  setZip: (zip: string) => void;
  setCgv: (cgv: boolean) => void;
  setNewsletter: (newsletter: boolean) => void;
  setSpecialty: (specialty: string) => void;
  setCountry: (country: string) => void;
  nextStep: (steps?: number) => void;
  setStatus: (status: string) => void;
  setRppsData: (data: RPPSData) => void;
  reset: () => void;
};

type RPPSData = {
  firstName: string;
  lastName: string;
  job: string;
  rpps: string;
} | null;

type State = {
  currentStep: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  domain: string;
  zip: string;
  cgv: boolean;
  newsletter: boolean;
  specialty: string;
  country: string;
  status: string;
  rppsData?: RPPSData;
};

const initialState: State = {
  currentStep: 0,
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  domain: "",
  zip: "",
  cgv: false,
  newsletter: false,
  specialty: "",
  country: "",
  status: "",
};

const useRegistrationForm = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setStatus: (status: string) => set({ status }),
      setFirstName: (name: string) => set({ firstName: name }),
      setLastName: (name: string) => set({ lastName: name }),
      setDomain: (domain: string) => set({ domain }),
      setZip: (zip: string) => set({ zip }),
      setCgv: (cgv: boolean) => set({ cgv }),
      setNewsletter: (newsletter: boolean) => set({ newsletter }),
      setSpecialty: (specialty: string) => set({ specialty }),
      setCountry: (country: string) => set({ country }),
      setEmail: (email: string) => set({ email }),
      setPassword: (password: string) => set({ password }),
      setRppsData: (data: RPPSData) => set({ rppsData: data }),
      nextStep: (steps = 1) =>
        set((state) => ({ currentStep: state.currentStep + steps })),
      reset: () => set(initialState),
    }),
    {
      name: "registration-form",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useRegistrationForm;
