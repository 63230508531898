import { Box, Link as ChakraLink, Stack, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

type NavButtonProps = {
  icon: React.ReactNode;
  label: string;
  color: string;
  to: string;
};

const NavButton = (props: NavButtonProps) => {
  const { icon, label, color, to } = props;

  return (
    <ChakraLink
      _hover={{
        textDecoration: "none",
      }}
      as={ReactRouterLink}
      to={to}
    >
      <Stack
        direction="row"
        backgroundColor={"rgba(241, 245, 248, 0.50);"}
        borderRadius={10}
        padding={2}
        gap={4}
        alignItems={"center"}
        _hover={{
          background: "rgba(241, 245, 248, 1);",
        }}
      >
        <Box
          width={10}
          height={10}
          background={color}
          display={"flex"}
          flexShrink={0}
          borderRadius={5}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {icon}
        </Box>
        <Text>{label}</Text>
      </Stack>
    </ChakraLink>
  );
};

export default NavButton;
