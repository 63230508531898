import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";

type PaginationProps = {
  total: number;
  current: number;
  onChange: (page: number) => void;

  resultsPerPage?: number;
};

const Pagination = (props: PaginationProps) => {
  const { total, current, onChange, resultsPerPage = 10 } = props;

  const pages = Math.ceil(total / resultsPerPage);

  if (pages <= 1) {
    return null;
  }

  if (pages > 5) {
    return (
      <ButtonGroup size={["sm", "md"]} variant={"outline"} isAttached>
        <IconButton
          aria-label="First Page"
          icon={<ArrowLeftIcon fontSize={8} />}
          disabled={current === 1}
          onClick={() => onChange(1)}
        />
        <IconButton
          aria-label="Previous Page"
          icon={<ChevronLeftIcon />}
          disabled={current === 1}
          onClick={() => current > 1 && onChange(current - 1)}
        />
        {/**
         * Show 5 buttons at a time, if current page is less than 3, show 1-5
         */}
        {current < 3 &&
          Array.from({ length: 5 }, (_, i) => (
            <Button
              key={i}
              onClick={() => onChange(i + 1)}
              color={i + 1 === current ? "brand.500" : "#000"}
            >
              {i + 1}
            </Button>
          ))}
        {/* If current page is greater than 3, show current - 2 to current + 2 */}
        {current >= 3 &&
          current <= pages - 2 &&
          Array.from({ length: 5 }, (_, i) => (
            <Button
              key={i}
              onClick={() => onChange(current + i - 2)}
              color={current + i - 2 === current ? "brand.500" : "#000"}
            >
              {current + i - 2}
            </Button>
          ))}
        {/* If current page is greater than pages - 2, show pages - 4 to pages */}
        {current > pages - 2 &&
          Array.from({ length: 5 }, (_, i) => (
            <Button
              key={i}
              onClick={() => onChange(pages - 4 + i)}
              color={pages - 4 + i === current ? "brand.500" : "#000"}
            >
              {pages - 4 + i}
            </Button>
          ))}

        <IconButton
          aria-label="Next Page"
          icon={<ChevronRightIcon />}
          disabled={current === pages}
          onClick={() => current < pages && onChange(current + 1)}
        />
        <IconButton
          aria-label="Last Page"
          icon={<ArrowRightIcon fontSize={8} />}
          disabled={current === pages}
          onClick={() => onChange(pages)}
        />
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup size={["sm", "md"]} variant={"outline"} isAttached>
      <IconButton
        aria-label="First Page"
        icon={<ArrowLeftIcon fontSize={8} />}
        disabled={current === 1}
        onClick={() => onChange(1)}
      />
      <IconButton
        aria-label="Previous Page"
        icon={<ChevronLeftIcon />}
        disabled={current === 1}
        onClick={() => current > 1 && onChange(current - 1)}
      />
      {Array.from({ length: pages }, (_, i) => (
        <Button
          key={i}
          onClick={() => onChange(i + 1)}
          color={i + 1 === current ? "brand.500" : "#000"}
        >
          {i + 1}
        </Button>
      ))}
      <IconButton
        aria-label="Next Page"
        icon={<ChevronRightIcon />}
        disabled={current === pages}
        onClick={() => current < pages && onChange(current + 1)}
      />
      <IconButton
        aria-label="Last Page"
        icon={<ArrowRightIcon fontSize={8} />}
        disabled={current === pages}
        onClick={() => onChange(pages)}
      />
    </ButtonGroup>
  );
};

export default Pagination;
