import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";

import i18n from "@/i18n";
import { Tool } from "../types";
import { defer } from "react-router-dom";

const toolboxLoader = async () => {
  const toolsRef = collection(db, "toolbox");
  const toolsSnapshot = await getDocs(toolsRef);
  const tools = toolsSnapshot.docs.map((doc) => ({
    id: doc.id,
    name: doc.data().name,

    category: doc.data().category as DocumentReference | null,
    url: doc.data().url,
    image: doc.data().image,
  }));
  const _categories = {} as Record<string, Tool[]>;
  const standAloneTools: Tool[] = [];

  tools.forEach((tool) => {
    if (!tool.category) {
      standAloneTools.push(tool);
      return;
    }
    if (_categories[tool.category.path]) {
      _categories[tool.category.path].push(tool);
    } else {
      _categories[tool.category.path] = [tool];
    }
  });

  const categories = Promise.all(
    Object.entries(_categories).map(async ([category, value]) => {
      const _category = await getDoc(doc(db, category));
      if (!_category.exists())
        return {
          category: "",
          tools: value,
        };

      return {
        category:
          _category.data().localizedTitle[i18n.language] ||
          _category.data().localizedTitle.en,
        tools: value,
      };
    })
  );

  return defer({ categories, standAloneTools });
};

export default toolboxLoader;
