import { authIa, dbIa } from "@/lib/firebase";
import { User } from "@/lib/types";
import { onAuthStateChanged } from "firebase/auth";
import { Timestamp, doc, onSnapshot } from "firebase/firestore";

import { createContext, useContext, useEffect, useRef, useState } from "react";

type UserSessionContextType = {
  user: User | null;
  isReady: boolean;
};

const UserSessionContext = createContext<UserSessionContextType>({
  user: null,
  isReady: false,
});

const UserSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isReady, setIsReady] = useState(false);
  const unsubscribe = useRef<() => void>(() => {});
  useEffect(() => {
    onAuthStateChanged(authIa, async (user) => {
      if (user?.email) {
        unsubscribe.current = onSnapshot(
          doc(dbIa, "users", user.email),
          (_doc) => {
            if (_doc.exists() === false) {
              setUser({
                id: user.email as string,
                ref: doc(dbIa, "users", user.email as string),
                createdAt: new Timestamp(Date.now() / 1000, 0),
                liked: [],
                firstName: "",
                lastName: "",
                status: "",
                domain: "",
                specialty: "",
                country: "",
                zip: "",
                cgv: false,
                newsletter: false,
                rpps: null,
                paperCoins: 500,
              });
            } else {
              setUser({
                ..._doc.data(),
                id: _doc.id,
                ref: _doc.ref,
              } as User);
              setIsReady(true);
            }
          }
        );
      } else {
        unsubscribe.current();
        setUser(null);
        setIsReady(true);
      }
    });
  }, []);
  return (
    <UserSessionContext.Provider value={{ user: user, isReady: isReady }}>
      {children}
    </UserSessionContext.Provider>
  );
};

const useUserSession = (): UserSessionContextType =>
  useContext(UserSessionContext);

export { useUserSession };
export default UserSessionProvider;
