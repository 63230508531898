import ExportMenu from "@/components/collections/ExportMenu";
import Archie from "@/components/search/Archie";
import SearchResults from "@/components/search/SearchResults";

import {
  AugmentedSearchResponse,
  ClassicSearchResponse,
} from "@/lib/SemanticScholarApi";
import Container from "@/ui/Container";
import Pagination from "@/ui/Pagination";
import SearchArticle from "@/ui/SearchArticle";
import SearchLoading from "@/ui/pending/SearchLoading";

import { Box, Text } from "@chakra-ui/react";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Await,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";

const Search = () => {
  const { results, page, query } = useLoaderData() as {
    results: Promise<ClassicSearchResponse | AugmentedSearchResponse>;
    page: number;
    query: string | undefined;
  };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const busy =
    navigation.state === "loading" &&
    navigation.location.pathname === "/search";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, query, busy]);

  const { t } = useTranslation();

  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <>
          {busy ? (
            <SearchLoading />
          ) : (
            <>
              <Suspense fallback={<SearchLoading />}>
                <Await
                  resolve={results}
                  errorElement={<Text>{t("search.error")}</Text>}
                >
                  {(
                    results: ClassicSearchResponse | AugmentedSearchResponse
                  ) => {
                    if (results.isAiSearch) {
                      return (
                        <>
                          <Archie data={results} />
                          <SearchResults />
                        </>
                      );
                    }

                    return (
                      <>
                        <Box
                          gap={8}
                          p={2}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Box alignSelf={"flex-end"}>
                            <ExportMenu
                              papers={results.data}
                              title={query as string}
                            />
                          </Box>
                          {results.data.map((document) => (
                            <SearchArticle
                              key={document.paperId}
                              {...document}
                            />
                          ))}
                        </Box>
                        <Pagination
                          total={results.total > 1000 ? 1000 : results.total}
                          current={page}
                          onChange={(currentPage) => {
                            const queryParams = new URLSearchParams(
                              window.location.search
                            );

                            queryParams.set("page", currentPage.toString());
                            navigate({
                              search: queryParams.toString(),
                            });
                          }}
                          resultsPerPage={10}
                        />
                      </>
                    );
                  }}
                </Await>
              </Suspense>
            </>
          )}
        </>
      </Box>
    </Container>
  );
};

export default Search;
