import { useUserSession } from "@/hooks/useUserSession";
import { PaperResponse } from "@/lib/SemanticScholarApi";

import Container from "@/ui/Container";
import HomeArticle from "@/ui/HomeArticle";
import MainCard from "@/ui/MainCard";
import HomeLoading from "@/ui/pending/HomeLoading";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Suspense, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Await, useLoaderData } from "react-router-dom";

const Home = () => {
  const { articles } = useLoaderData() as {
    articles: Promise<PaperResponse[]>;
  };

  const { t } = useTranslation();
  const { user } = useUserSession();

  const [displayedArticles, setDisplayedArticles] = useState(10);

  return (
    <Container hasSearch>
      <MainCard
        bgImage={"/backgrounds/logo-brand.svg"}
        bgSize={"cover"}
        p={[4, 12]}
        display={"flex"}
        flexDirection={"column"}
        bgAttachment={"scroll"}
        bgPosition={"center"}
        justifyContent={"center"}
        w={"100%"}
      >
        <Box>
          <Heading
            as={"h1"}
            fontSize={"2xl"}
            color={"white"}
            textAlign={"center"}
            mb={4}
            sx={{
              span: {
                fontWeight: 400,
              },
            }}
          >
            <Trans t={t} i18nKey={"home.title"}>
              Les articles recommandés <span> pour vous,</span>{" "}
              {{ name: user?.firstName || "" }}
            </Trans>
          </Heading>
          <Text
            fontStyle={"italic"}
            color={"white"}
            textAlign={"center"}
            mb={4}
          >
            {t("home.subtitle")}
          </Text>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={4}
          pb={30}
        >
          <Flex flex={1} direction={"column"} gap={4} mb={4}>
            <Suspense fallback={<HomeLoading />}>
              <Await resolve={articles}>
                {(articles: PaperResponse[]) => {
                  return articles
                    .slice(0, displayedArticles)
                    .map((article, i) =>
                      i % 2 === 0 ? (
                        <HomeArticle key={article.paperId} {...article} />
                      ) : null
                    );
                }}
              </Await>
            </Suspense>
          </Flex>
          <Flex
            flex={1}
            direction={"column"}
            gap={4}
            pb={4}
            sx={{
              "> *:last-child": {
                marginBottom: 10,
              },
            }}
          >
            <Suspense fallback={<HomeLoading />}>
              <Await resolve={articles}>
                {(articles: PaperResponse[]) => {
                  return articles
                    .slice(0, displayedArticles)
                    .map((article, i) =>
                      i % 2 !== 0 ? (
                        <HomeArticle key={article.paperId} {...article} />
                      ) : null
                    );
                }}
              </Await>
            </Suspense>
          </Flex>
        </Box>
        <Suspense fallback={null}>
          <Await resolve={articles}>
            {(articles: PaperResponse[]) => {
              if (articles.length > displayedArticles) {
                return (
                  <Flex justifyContent={"center"}>
                    <Button
                      colorScheme="whiteAlpha"
                      onClick={() =>
                        setDisplayedArticles(displayedArticles + 10)
                      }
                    >
                      {t("home.loadMore")}
                    </Button>
                  </Flex>
                );
              }
            }}
          </Await>
        </Suspense>
      </MainCard>
    </Container>
  );
};

export default Home;
