import { useUserSession } from "@/hooks/useUserSession";
import { functions } from "@/lib/firebase";

import AuthContainer from "@/ui/Auth/AuthContainer";
import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { httpsCallable } from "firebase/functions";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
  name: string;
  message: string;
};

const Contact = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      email: user?.id || "",
      name: user ? `${user.firstName} ${user.lastName}` : "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const sendEmail = httpsCallable(functions, "sendContactEmail");

      sendEmail(data);

      toast({
        title: t("contact.success"),
        description: t("contact.successDescription"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      toast({
        title: t("contact.error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  });

  return (
    <AuthContainer pt={8}>
      <Heading mb={8} color={"#fff"}>
        {t("contact.title")}
      </Heading>

      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={8}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <Input
          variant={"auth"}
          placeholder={t("auth.login.email")}
          {...register("email")}
          required
        />
        {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
        <Input
          variant={"auth"}
          placeholder={t("contact.name")}
          {...register("name")}
          required
        />
        {errors.name && <Box color={"red"}>{errors.name.message}</Box>}

        <Textarea
          variant={"auth"}
          placeholder={t("contact.message")}
          {...register("message")}
          required
        />
        <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
          {t("contact.submit")}
        </Button>

        <Text
          color={"#fff"}
          as={Link}
          to={"/"}
          textAlign={"center"}
          textDecoration={"underline"}
        >
          {t("contact.return")}
        </Text>
      </Box>
    </AuthContainer>
  );
};

export default Contact;
