import { useUserSession } from "@/hooks/useUserSession";
import { authIa } from "@/lib/firebase";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Box, Button, Heading, Input, Text, useToast } from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
};

const ForgottenPassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { user } = useUserSession();
  const toast = useToast();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      await sendPasswordResetEmail(authIa, data.email);
      toast({
        title: t("auth.password.resetSuccess"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t("auth.password.resetError"),
        description: t("auth.password.resetErrorDescription"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  });

  return (
    <AuthContainer pt={8}>
      <Heading mb={8} color={"#fff"}>
        {t("auth.password.title")}
      </Heading>

      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={8}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <Input
          variant={"auth"}
          placeholder={t("auth.login.email")}
          {...register("email")}
          type="email"
          required
        />
        {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
        <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
          {t("auth.password.submit")}
        </Button>

        <Text
          color={"#fff"}
          as={Link}
          to="/login"
          textAlign={"center"}
          textDecoration={"underline"}
        >
          {t("auth.login.submit")}
        </Text>
      </Box>
    </AuthContainer>
  );
};

export default ForgottenPassword;
