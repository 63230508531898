import useRegistrationForm from "@/hooks/useRegistrationForm";

import {
  Button,
  Link as ChakraLink,
  Text,
  Box,
  Stack,
  Progress,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";

type Item = {
  label: string;
  value: string;
};

type FormData = {
  specialty: string;
};

const Specialty = () => {
  const { t, i18n } = useTranslation();
  const { setSpecialty, nextStep, specialty } = useRegistrationForm();
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      specialty: specialty,
    },
  });
  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (data) => {
    setSpecialty(data.specialty);

    nextStep();
    navigate("/register/identity");
  });

  const value = watch("specialty");

  const [specialties, setSpecialties] = useState<Item[]>([]);

  useEffect(() => {
    (async () => {
      const { docs } = await getDocs(collection(dbIa, "specialties"));

      const _specialties = docs.map((doc) => ({
        label: doc.data()[i18n.language],
        value: doc.id,
      }));
      setSpecialties(_specialties);
    })();
  }, [i18n.language]);

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Text mb={-6} textAlign={"center"}>
        {t("auth.register.winCoins")}
      </Text>
      <Progress value={60} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}
      <AutoComplete
        openOnFocus
        value={specialties.find((item) => item.value === value)?.label}
        onSelectOption={({ item }: { item: Item }) => {
          setValue("specialty", item.value);
        }}
      >
        <AutoCompleteInput
          variant={"auth"}
          placeholder={t("auth.register.specialty")}
          {...register("specialty")}
          required
        />
        <AutoCompleteList>
          {specialties.map((status) => (
            <AutoCompleteItem
              color="#000"
              key={status.value}
              value={status.value}
              label={status.label}
            >
              {status.label}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.continue")}
      </Button>
      <Stack
        direction={"column"}
        spacing={0}
        alignSelf={"center"}
        textAlign={"center"}
      >
        <Text>{t("auth.register.alreadyHaveAccount")} </Text>
        <ChakraLink textDecoration={"underline"} as={Link} to={"/login"}>
          {t("auth.register.signIn")}
        </ChakraLink>
      </Stack>
    </Box>
  );
};

export default Specialty;
