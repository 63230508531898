import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";

import en from "./locales/en.json";
import fr from "./locales/fr.json";

const resources = {
  en: { translation: en },
  fr: { translation: fr },
};

i18n
  .use(ICU)
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {},
  });

export default i18n;
