import useArchieData from "@/hooks/useArchieData";
import { Icon, IconButton, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdContentCopy } from "react-icons/md";
//@ts-expect-error citation-js does not have types
import Cite from "citation-js";

type CopyContentProps = {
  contentRef: React.RefObject<HTMLDivElement>;
  refs: Array<string>;
  textStart?: string;
};
const CopyContent = ({
  contentRef,
  refs,
  textStart = "",
}: CopyContentProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { papers } = useArchieData((state) => state);
  const handleClick = () => {
    const baseText = contentRef.current?.innerText;
    if (!baseText) {
      console.error("No text to copy");
      return;
    }

    const bibliography = refs.map((id, i) => {
      const paper = papers.find((paper) => paper.corpusId === parseInt(id));
      if (!paper) {
        console.error("Paper not found", id, i);
        return "";
      }
      const bibtext = paper.citationStyles?.bibtex;
      if (bibtext) {
        const cite = new Cite(paper.citationStyles?.bibtex);
        return cite.format("bibliography", {
          template: "apa",
          format: "text",
        });
      }

      const doi = paper.externalIds.DOI;
      if (!doi) {
        return "";
      }
      const cite = new Cite(doi);
      return cite.format("bibliography", {
        template: "apa",
        format: "text",
      });
    });

    const regex = /\[(\d+)\]/g;
    const parts: string[] = [];
    let match: RegExpExecArray | null;
    let lastIndex = 0;
    const sources: Array<string> = [];

    while ((match = regex.exec(baseText)) !== null) {
      // Ajouter le texte avant le match actuel
      if (match.index > lastIndex) {
        parts.push(baseText.substring(lastIndex, match.index));
      }

      // Ajouter le composant de substitution pour le match actuel

      let index = sources.indexOf(match[1]);
      if (index === -1) {
        sources.push(match[1]);
        index = sources.length;
        parts.push(`(${index})`);
      } else {
        parts.push(`(${index + 1})`);
      }

      // Mettre à jour le lastIndex pour le prochain tour de boucle
      lastIndex = regex.lastIndex;
    }

    // Ajouter le reste du texte après le dernier match
    if (lastIndex < baseText.length) {
      parts.push(baseText.substring(lastIndex));
    }
    const parsedText = parts.join("");

    const finalText =
      textStart +
      parsedText +
      "\n\n" +
      t("archie.source") +
      ":\n" +
      bibliography
        .slice(0, sources.length)
        .map((citation, i) => {
          return `${i + 1}. ${citation}`;
        })
        .join("\n");

    window.navigator.clipboard.writeText(finalText);
    toast({
      title: t("archie.copied"),
      status: "success",
    });
  };
  return (
    <IconButton
      onClick={handleClick}
      aria-label="copy"
      icon={<Icon as={MdContentCopy} />}
    />
  );
};

export default CopyContent;
