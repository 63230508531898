import { useUserSession } from "@/hooks/useUserSession";
import { authIa } from "@/lib/firebase";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Box, Button, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useUserSession();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      setSubmitError(null);
      await signInWithEmailAndPassword(authIa, data.email, data.password);
      navigate("/", { replace: true });
    } catch (error) {
      console.error(error);

      if (error === "auth/user-not-found") {
        setSubmitError(t("auth.login.notFound"));
      }
      setSubmitError(t("auth.login.error"));
    }
  });

  return (
    <AuthContainer>
      <Heading mb={8} color={"#fff"}>
        {t("auth.login.title")}
      </Heading>
      {submitError && <Box color={"red"}>{submitError}</Box>}
      <Box
        as="form"
        display={"flex"}
        flexDir={"column"}
        gap={8}
        w={"clamp(280px, 70%, 500px)"}
        onSubmit={onSubmit}
      >
        <Input
          variant={"auth"}
          placeholder={t("auth.login.email")}
          {...register("email")}
          required
        />
        {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
        <Input
          variant={"auth"}
          placeholder={t("auth.login.password")}
          {...register("password")}
          required
          type="password"
        />
        {errors.password && <Box color={"red"}>{errors.password.message}</Box>}
        <Button colorScheme="brand" type="submit" isLoading={isSubmitting}>
          {t("auth.login.submit")}
        </Button>
        <Stack alignSelf={"center"} spacing={0}>
          <Text textAlign={"center"} color={"#fff"}>
            {t("auth.login.noAccount")}
          </Text>
          <Text
            color={"#fff"}
            as={Link}
            to="/register"
            textAlign={"center"}
            textDecoration={"underline"}
          >
            {t("auth.login.register")}
          </Text>
          <Text
            mt={4}
            color={"#fff"}
            as={Link}
            to="/forgot-password"
            textAlign={"center"}
            textDecoration={"underline"}
          >
            {t("auth.login.passwordForgotten")}
          </Text>
        </Stack>
      </Box>
    </AuthContainer>
  );
};

export default Login;
