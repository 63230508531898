import { functions } from "@/lib/firebase";
import { httpsCallable } from "firebase/functions";

type ArchieYOQuestionRequest = {
  token: string;
};

type ArchieYOQuestionResponse = {
  type: "question";
  question: string;
  is_yes_no: boolean;
};

type ArchieYOAnswerResponse = {
  type: "response";
  answers: {
    [key: string]: {
      answer: "yes" | "no" | "potentially" | "irrelevant_paper";
      justification: string;
    };
  };
  answers_weight: {
    yes: number;
    no: number;
    potentially: number;
  };
  papers: Array<string>;
};

type ArchieYOAnswerRequest = {
  token: string;
  question: string;
};

const getArchieYOQuestion = httpsCallable<
  ArchieYOQuestionRequest,
  ArchieYOQuestionResponse
>(functions, "get_archie_yo_question");

const getArchieYOAnswers = httpsCallable<
  ArchieYOAnswerRequest,
  ArchieYOAnswerResponse
>(functions, "get_archie_yo_answers");

export { getArchieYOQuestion, getArchieYOAnswers };
