import { createContext, useContext, useEffect, useState } from "react";
import { useUserSession } from "./useUserSession";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Collection } from "@/lib/types/Collection";

type TCollectionsContext = {
  collections: Array<Collection>;
  openCreateCollection: () => void;
  deleteCollection: (collectionId: string) => Promise<void>;
  renameCollection: (collectionId: string, newName: string) => Promise<void>;
  addElementToCollection: (
    elementType: "paper" | "search",
    collectionId: string,
    elementId: string
  ) => Promise<void>;
  removeElementFromCollection: (
    elementType: "paper" | "search",
    collectionId: string,
    elementId: string
  ) => Promise<void>;
};

type CreateCollectionFormData = {
  name: string;
};

const defaultCollectionsContext = {
  collections: [],
  openCreateCollection: () => {},
  deleteCollection: async () => {},
  renameCollection: async () => {},
  addElementToCollection: async () => {},
  removeElementFromCollection: async () => {},
};
const CollectionsContext = createContext<TCollectionsContext>(
  defaultCollectionsContext
);

const CollectionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CreateCollectionFormData>();
  const {
    isOpen: isCollectionCreationOpen,
    onClose: closeCollectionCreation,
    onOpen: openCollectionCreation,
  } = useDisclosure();
  const { user } = useUserSession();
  useEffect(() => {
    if (user) {
      // fetch collections
      onSnapshot(
        query(collection(user.ref, "collections"), orderBy("createdAt", "asc")),
        (snapshot) => {
          const collections: Collection[] = [];
          snapshot.forEach((doc) => {
            collections.push({
              id: doc.id,
              ref: doc.ref,
              name: doc.data().name,
              createdAt: doc.data().createdAt.toDate(),
              papers: doc.data().papers,
              searches: doc.data().searches,
            });
          });
          if (collections.length === 0) {
            const collectionRef = doc(user.ref, "collections", "default");
            setDoc(collectionRef, {
              name: "default",
              createdAt: new Date(),
              papers: [],
              searches: [],
            });
          }
          setCollections(collections);
        }
      );
    } else {
      setCollections([]);
    }
  }, [user]);

  const handleCollectionCreation = handleSubmit(async (data) => {
    if (user) {
      const collectionsRef = collection(user.ref, "collections");
      await addDoc(collectionsRef, {
        name: data.name,
        createdAt: new Date(),
        papers: [],
        searches: [],
      });
      closeCollectionCreation();
      reset();
    }
  });

  const deleteCollection = async (collectionId: string) => {
    if (user) {
      const collectionRef = doc(user.ref, "collections", collectionId);
      await deleteDoc(collectionRef);
    }
  };

  const renameCollection = async (collectionId: string, newName: string) => {
    if (user) {
      const collectionRef = doc(user.ref, "collections", collectionId);
      await updateDoc(collectionRef, { name: newName });
    }
  };

  const addElementToCollection = async (
    elementType: "paper" | "search",
    collectionId: string,
    elementId: string
  ) => {
    if (user) {
      const property = elementType == "paper" ? "papers" : "searches";
      const collectionRef = doc(user.ref, "collections", collectionId);
      await updateDoc(collectionRef, {
        [property]: arrayUnion(elementId),
      });
    }
  };

  const removeElementFromCollection = async (
    elementType: "paper" | "search",
    collectionId: string,
    elementId: string
  ) => {
    if (user) {
      const property = elementType == "paper" ? "papers" : "searches";
      const collectionRef = doc(user.ref, "collections", collectionId);
      await updateDoc(collectionRef, {
        [property]: arrayRemove(elementId),
      });
    }
  };

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        openCreateCollection: openCollectionCreation,
        deleteCollection,
        renameCollection,
        addElementToCollection,
        removeElementFromCollection,
      }}
    >
      {children}
      <Modal
        isOpen={isCollectionCreationOpen}
        onClose={closeCollectionCreation}
        size={"xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bgColor={"#F2F6F9"}>
          <ModalHeader>{t("collections.creation.create")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleCollectionCreation}>
            <ModalBody>
              <FormLabel>{t("collections.creation.name")}</FormLabel>
              <Input
                variant={"auth"}
                outline={"2px solid #F2F6F9"}
                {...register("name", { required: true, maxLength: 100 })}
                placeholder={t("collections.creation.name")}
                isInvalid={errors.name !== undefined}
              />
              {errors.name && (
                <FormErrorMessage>
                  {t("collections.creation.required")}
                </FormErrorMessage>
              )}
            </ModalBody>
            <ModalFooter gap={4}>
              <Button type="reset" onClick={closeCollectionCreation}>
                {t("common.cancel")}
              </Button>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="brand"
              >
                {t("collections.creation.create")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </CollectionsContext.Provider>
  );
};

const useCollections = () => useContext(CollectionsContext);

export { CollectionsProvider, useCollections };
