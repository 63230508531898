import { Tool } from "@/lib/types";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";

type ToolsProps = {
  title: string;
  tools: Tool[];
};

const Tools = (props: ToolsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, tools } = props;

  return (
    <GridItem
      cursor={"pointer"}
      onClick={() => setIsOpen(!isOpen)}
      display={"flex"}
      bgColor={"#fff"}
      colSpan={[1, isOpen ? 2 : 1]}
      as={motion.div}
      borderRadius={24}
      flexDir={"column"}
      p={8}
      gap={4}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignSelf={"center"}
        alignItems={"center"}
      >
        <Heading as={"h2"} size={"md"}>
          {title}
        </Heading>

        <ChevronDownIcon w={"2em"} h={"2em"} />
      </Stack>
      {isOpen && (
        <SimpleGrid minChildWidth="200px" spacing={4}>
          {tools.map((tool) => (
            <Box
              key={tool.id}
              bgColor={"#F2F6F9"}
              borderRadius={20}
              padding={4}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={4}
              as="a"
              href={tool.url}
              target="_blank"
            >
              <Image
                src={tool.image}
                alt={tool.name}
                w={50}
                h={50}
                objectFit={"contain"}
              />
              <Text
                textOverflow={"ellipsis"}
                overflow={"hidden"}
                sx={{
                  textWrap: "balance",
                }}
                textAlign={"center"}
                color={"#000"}
                fontSize={14}
              >
                {tool.name}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </GridItem>
  );
};

export default Tools;
