import Container from "@/ui/Container";
import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { ArrowBackIcon, LinkIcon } from "@chakra-ui/icons";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";

type Content = {
  title: string;
  value: {
    text: string;
    title: string;
  }[];
}[];

type Abstract = {
  title: string;
  text: string;
}[];

type ArticleData = {
  title: string;
  content: Content | undefined;
  abstract: Abstract;
  id: string;
  authors: string[];
  doi: string;
  journal: string;
  specialties: DocumentReference[];
  summary_ref: DocumentReference<DocumentData, DocumentData>;
  metadata_ref: DocumentReference<DocumentData, DocumentData>;
};

const ArticleBackup = () => {
  const { article } = useLoaderData() as { article: ArticleData };
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container hasSearch>
      <Box
        width={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
        mt={8}
        p={8}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        gap={8}
      >
        <Button
          variant={"primary"}
          w={"fit-content"}
          onClick={() => navigate(-1)}
        >
          <Box
            borderWidth={1}
            borderColor={"#FFF"}
            borderRadius={100}
            w={6}
            h={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mr={2}
          >
            <ArrowBackIcon />
          </Box>
          {t("article.back")}
        </Button>
        <Box
          overflow={"scroll"}
          p={8}
          display={"flex"}
          flexDirection={"column"}
          gap={8}
          borderRadius={24}
          bgColor={"#fff"}
        >
          <Heading as={"h1"}>{article.title}</Heading>
          <Stack>
            <Button
              as={"a"}
              href={`https://doi.org/${article.doi}`}
              target="_blank"
              w={"fit-content"}
            >
              {t("article.source")}
              <LinkIcon ml={2} />
            </Button>
            <Text>
              {article.journal} - {article.doi}
            </Text>
            <Text fontStyle={"italic"}>
              {article.authors.join(", ")} et al.
            </Text>
          </Stack>

          {article.content
            ? article.content.map((part) => (
                <Box key={part.title}>
                  <Heading size={"md"} as={"h2"}>
                    {part.title}
                  </Heading>
                  {part.value.map((value) => (
                    <Text mb={2} key={value.text}>
                      {value.text}
                    </Text>
                  ))}
                </Box>
              ))
            : article.abstract.map((part) => (
                <Box key={part.title}>
                  <Text as={"h2"}>{part.title}</Text>
                  <Text>{part.text}</Text>
                </Box>
              ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ArticleBackup;
