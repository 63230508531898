import useRegistrationForm from "@/hooks/useRegistrationForm";
import { useUserSession } from "@/hooks/useUserSession";
import { dbIa } from "@/lib/firebase";

import {
  Button,
  Link as ChakraLink,
  Box,
  Progress,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { doc, increment, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type FormData = {
  cgv: boolean;
  newsletter: boolean;
};

const OldTerms = () => {
  const { t } = useTranslation();
  const {
    setCgv,
    setNewsletter,
    firstName,
    lastName,
    country,
    zip,
    specialty,
    status,
    domain,
    reset,
    rppsData,
  } = useRegistrationForm();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useUserSession();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setCgv(data.cgv);
      setNewsletter(data.newsletter);

      await setDoc(
        doc(dbIa, "users", user?.id as string),
        {
          email: user?.id || "",
          firstName,
          lastName,
          country,
          zip,
          specialty,
          status,
          domain,
          cgv: data.cgv,
          newsletter: data.newsletter,
          rpps: rppsData?.rpps || null,
          isRegistrationComplete: true,
          paperCoins: increment(1000),
        },
        {
          merge: true,
        }
      );
      useRegistrationForm.persist.clearStorage();
      reset();
      toast({
        title: t("auth.register.accountUpdated"),
        description: t("auth.register.accountUpdatedDescription"),
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/");
    } catch (error) {
      setError("root", {
        type: "manual",
        message: t("auth.register.error"),
      });
    }
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Progress value={100} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}

      <Checkbox colorScheme="brand" required {...register("cgv")}>
        <Trans t={t} key={"auth.register.acceptCgv"}>
          J'ai lu et j'accepte les{" "}
          <ChakraLink
            textDecoration={"underline"}
            target="_blank"
            href="https://paperdoc-app.com/fr/privacy-policy"
          >
            conditions générales d'utilisation
          </ChakraLink>
        </Trans>
      </Checkbox>
      <Checkbox colorScheme="brand" {...register("newsletter")}>
        {t("auth.register.newsletter")}
      </Checkbox>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.createAccount")}
      </Button>
    </Box>
  );
};

export default OldTerms;
