import { useUserSession } from "@/hooks/useUserSession";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

const OldRegister = () => {
  const { t } = useTranslation();

  const { user } = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    const date = user?.createdAt.toDate();
    if (
      user?.isRegistrationComplete ||
      (date && date > new Date("2024-05-07"))
    ) {
      navigate("/", {
        replace: true,
      });
    }
  }, [user, navigate]);

  return (
    <>
      {" "}
      <AuthContainer>
        <Heading mb={8} color={"#fff"}>
          {t("auth.register.subtitle.base")}
        </Heading>
        <Text>{t("auth.complete.why")}</Text>

        <Text mb={-3}>{t("auth.old.subtitle.base")}</Text>
        <Outlet />
      </AuthContainer>
    </>
  );
};

export default OldRegister;
