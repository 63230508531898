import { useTranslation } from "react-i18next";
import styles from "./summarize.module.css";
import { Text } from "@chakra-ui/react";

const progressValues = [25, 50, 75, 100];

const ProgressBar = ({
  step,
  textColor = "#fff",
}: {
  step: number;
  textColor?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{ width: `${progressValues[step]}%` }}
        ></div>
      </div>
      <div
        className={styles.progressTextContainer}
        style={{
          marginLeft: `${progressValues[step]}%`,
        }}
      >
        <Text color={textColor} className={styles.progressTitle}>
          {t("summarize.step")} {step + 1}
        </Text>
        <Text color={textColor} className={styles.progressText}>
          {step === 0 && t("summarize.steps.upload")}
          {step === 1 && t("summarize.steps.processing")}
          {step === 2 && t("summarize.steps.formatting")}
          {step === 3 && t("summarize.steps.done")}
        </Text>
      </div>
    </div>
  );
};

export default ProgressBar;
