import { doc, getDoc, setDoc } from "firebase/firestore";
import { analytics, authIa, dbIa } from "../firebase";
import { PapersHit } from "../types";
import semanticScholarApi, { SearchResponseItem } from "../SemanticScholarApi";

import * as Sentry from "@sentry/react";
import { logEvent } from "firebase/analytics";

const articleLoader = async ({
  params,
}: {
  params: {
    articleId: string;
    slug: string;
  };
}) => {
  const { articleId } = params;

  logEvent(analytics, "open_article");

  const result = await new Promise<SearchResponseItem>((resolve) => {
    semanticScholarApi.search
      .get(`/paper/CorpusId:${articleId}`, {
        params: {
          fields:
            "corpusId,url,title,venue,publicationVenue,year,authors,externalIds,abstract,citationCount,influentialCitationCount,isOpenAccess,openAccessPdf,s2FieldsOfStudy,publicationTypes,publicationDate,journal,tldr,citationStyles",
        },
      })
      .then(async (res) => {
        const result = res.data;

        try {
          setDoc(
            doc(
              dbIa,
              "users",
              authIa.currentUser?.email as string,
              "history",
              articleId
            ),
            {
              readAt: new Date(),
              id: articleId,
            }
          );
        } catch (error) {
          Sentry.captureException(error);
        }

        resolve(result);
      });
  });

  return {
    article: result,
  };
};

const oldArticleLoader = async ({
  params,
}: {
  params: {
    articleId: string;
  };
}) => {
  const { articleId } = params;
  const metadataDoc = await getDoc(doc(dbIa, "papers_ss", articleId));
  if (!metadataDoc.exists()) {
    return { article: null };
  }
  const metadata = metadataDoc.data() as PapersHit;

  const summaryDoc = await getDoc(
    doc(dbIa, "articles_summary", metadata.externalids.PubMed || "failed")
  );
  let summary;
  if (summaryDoc.exists()) {
    summary = summaryDoc.data();
  }
  const title = metadata.title;
  let abstract = metadata.abstract;

  if (!abstract?.en) {
    try {
      const res = await fetch(
        `https://api.semanticscholar.org/graph/v1/paper/CorpusId:${articleId}?fields=abstract`
      );
      const data = await res.json();

      abstract = {
        en: data.abstract,
        fr: "",
      };
      /*  updateDoc(metadataDoc.ref, {
        abstract,
      }); */
    } catch (error) {
      console.log(error);
    }
  }

  const content = summary?.summary || "";
  const final = {
    id: articleId,
    metadata_ref: metadataDoc.ref,
    summary_ref: summaryDoc.ref,
    title,
    abstract,
    content,
    specialties: metadata.specialties,
    journal: metadata.journal,
    authors: metadata.authors,
    doi: metadata.externalids.DOI,
    externalids: metadata.externalids,
    tldr_text: metadata.tldr_text,
    reference:
      metadata.reference ||
      `${metadata.authors.map((a) => a.name).join(", ")}. (${metadata.year}) ${
        metadata.journal?.name
      }. ${metadata.externalids.DOI}`,
  };

  setDoc(
    doc(
      dbIa,
      "users",
      authIa.currentUser?.email as string,
      "history",
      articleId
    ),
    {
      readAt: new Date(),
      path: "/papers_ss/" + articleId,
    }
  );

  return { article: final };
};
const backupArticleLoader = async ({
  params,
}: {
  params: {
    articleId: string;
  };
}) => {
  const { articleId } = params;
  const metadataDoc = await getDoc(doc(dbIa, "articles_metadata", articleId));
  if (!metadataDoc.exists()) {
    return { article: null };
  }
  const metadata = metadataDoc.data();
  const summaryDoc = await getDoc(doc(dbIa, "articles_summary", articleId));
  const summary = summaryDoc.data();
  const title = metadata.title.en;
  const abstract = metadata.abstract.en;
  const content = summary?.summary.en;
  const final = {
    id: articleId,
    metadata_ref: metadataDoc.ref,
    summary_ref: summaryDoc.ref,
    title,
    abstract,
    content,
    specialties: metadata.specialties,
    journal: metadata.journal,
    authors: metadata.authors,
    doi: metadata.doi,
  };

  return { article: final };
};
export { articleLoader, backupArticleLoader, oldArticleLoader };
