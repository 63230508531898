import useRegistrationForm from "@/hooks/useRegistrationForm";

import { Button, Input, Box, Progress, Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import countries from "i18n-iso-countries";
import countriesEn from "i18n-iso-countries/langs/en.json";
import countriesFr from "i18n-iso-countries/langs/fr.json";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/lib/firebase";
type Item = {
  label: string;
  value: string;
};
type FormData = {
  firstName: string;
  lastName: string;
  country: string;
  zip: string;
};
countries.registerLocale(countriesEn);
countries.registerLocale(countriesFr);
const Identity = () => {
  const { t, i18n } = useTranslation();
  const _countries = countries.getNames(i18n.language);

  const {
    setCountry,
    setFirstName,
    setLastName,
    setZip,
    nextStep,
    setRppsData,
    country,
    firstName,
    lastName,
    zip,
    status,
  } = useRegistrationForm();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      country: country,
      firstName: firstName,
      lastName: lastName,
      zip: zip,
    },
  });
  const navigate = useNavigate();
  const value = watch("country");
  const onSubmit = handleSubmit(async (data) => {
    setCountry(data.country);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setZip(data.zip);

    if (status != "patient") {
      const checkRpps = httpsCallable<
        {
          firstName: string;
          lastName: string;
        },
        {
          firstName: string;
          lastName: string;
          rpps: string;
          job: string;
        } | null
      >(functions, "checkRpps");
      const result = await checkRpps({
        firstName: data.firstName,
        lastName: data.lastName,
      });

      if (result.data) {
        setRppsData({
          ...result.data,
          firstName: data.firstName,
          lastName: data.lastName,
        });
      } else {
        setRppsData({
          firstName: data.firstName,
          lastName: data.lastName,
          job: "",
          rpps: "",
        });
      }

      nextStep();
      navigate("/register/validate-rpps");
    } else {
      nextStep(2);
      navigate("/register/terms");
    }
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Text mb={-6} textAlign={"center"}>
        {t("auth.register.winCoins")}
      </Text>
      <Progress value={80} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}
      <Flex
        justifyContent={["center", "space-between"]}
        flexDir={["column", "row"]}
        gap={8}
      >
        <Box w={["100%", "50%"]}>
          <Input
            type="text"
            placeholder={t("auth.login.firstName")}
            variant={"auth"}
            {...register("firstName")}
            required
          />
          {errors.firstName && (
            <Box color={"red"}>{errors.firstName.message}</Box>
          )}
        </Box>
        <Box w={["100%", "50%"]}>
          <Input
            type="text"
            placeholder={t("auth.login.lastName")}
            variant={"auth"}
            {...register("lastName")}
            required
          />
          {errors.lastName && (
            <Box color={"red"}>{errors.lastName.message}</Box>
          )}
        </Box>
      </Flex>
      <Flex
        justifyContent={["center", "space-between"]}
        flexDir={["column", "row"]}
        gap={8}
      >
        <Box w={["100%", "50%"]}>
          <AutoComplete
            openOnFocus
            onSelectOption={({ item }: { item: Item }) => {
              setValue("country", item.value);
            }}
            value={_countries[value]}
          >
            <AutoCompleteInput
              variant={"auth"}
              placeholder={t("auth.register.country")}
              {...register("country")}
              required
            />
            <AutoCompleteList>
              {Object.entries(_countries).map(([code, country]) => (
                <AutoCompleteItem
                  color="#000"
                  key={country}
                  value={code}
                  label={country}
                >
                  {country}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </AutoComplete>
          {errors.country && <Box color={"red"}>{errors.country.message}</Box>}
        </Box>
        <Box w={["100%", "50%"]}>
          <Input
            type="number"
            placeholder={t("auth.register.zip")}
            variant={"auth"}
            {...register("zip")}
            required
          />
          {errors.zip && <Box color={"red"}>{errors.zip.message}</Box>}
        </Box>
      </Flex>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.continue")}
      </Button>
    </Box>
  );
};

export default Identity;
