import ResponsiveSidebar from "@/components/ResponsiveSidebar";
import SearchBar from "@/components/SearchBar";
import { useUserSession } from "@/hooks/useUserSession";
import { authIa } from "@/lib/firebase";
import {
  Box,
  BoxProps,
  Button,
  HStack,
  IconButton,
  Image,
  Stack,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import HelpButton from "./HelpButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ContainerProps = BoxProps & {
  hasSearch?: boolean;
};
const Container = (props: ContainerProps) => {
  const { children, hasSearch, ...rest } = props;
  const { user } = useUserSession();
  const { t } = useTranslation();

  const logout = () => {
    signOut(authIa);
  };
  if (hasSearch) {
    return (
      <Box
        flexGrow={1}
        pb={10}
        px={[4, 10]}
        {...rest}
        position={"relative"}
        display={"flex"}
        flexDir={"column"}
        maxW={"100%"}
      >
        <Stack
          direction={"row"}
          spacing={10}
          alignItems={"flex-start"}
          justifyContent={"space-evenly"}
          position={"sticky"}
          top={0}
          py={6}
          zIndex={10}
          bgColor={"#E6F2F0"}
          hideBelow={"lg"}
          id="top-bar"
        >
          <Box w={"70%"}>
            <SearchBar />
          </Box>

          <Stack direction={"row"} spacing={4}>
            <Button as={Link} to={"/profile"} variant={"black"}>
              {t("container.profile")}
            </Button>
            <IconButton
              isRound
              aria-label={"Logout"}
              icon={<Image src="/icons/exit.svg" />}
              bgColor="white"
              filter={"drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));"}
              onClick={logout}
            />
            <HelpButton />
          </Stack>
        </Stack>
        <Stack
          direction={"column"}
          spacing={10}
          alignItems={"stretch"}
          justifyContent={"center"}
          position={"sticky"}
          top={0}
          left={0}
          right={0}
          py={6}
          zIndex={10}
          bgColor={"#E6F2F0"}
          hideFrom={"lg"}
          id="top-bar"
        >
          <HStack justifyContent={"space-between"}>
            <ResponsiveSidebar />
            <HStack>
              <Button as={Link} to={"/profile"} variant={"black"}>
                {t("container.profile", { name: user?.firstName || "" })}
              </Button>
              <IconButton
                isRound
                aria-label={"Logout"}
                icon={<Image src="/icons/exit.svg" />}
                bgColor="white"
                filter={"drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));"}
                onClick={logout}
              />
              <HelpButton />
            </HStack>
          </HStack>
          <SearchBar />
        </Stack>
        {children}
      </Box>
    );
  }

  return (
    <Box flexGrow={1} p={10} {...rest} position={"relative"}>
      <Stack
        direction={"row"}
        spacing={4}
        right={6}
        position={"sticky"}
        top={0}
        py={6}
        zIndex={10}
        bgColor={"#E6F2F0"}
        justifyContent={"flex-end"}
        maxW={"100%"}
        id="top-bar"
      >
        <Box hideFrom={"lg"} mr={"auto"}>
          <ResponsiveSidebar />
        </Box>
        <Button as={Link} to={"/profile"} variant={"black"}>
          {t("container.profile", { name: user?.firstName || "" })}
        </Button>
        <IconButton
          isRound
          aria-label={"Logout"}
          icon={<Image src="/icons/exit.svg" />}
          bgColor="white"
          filter={"drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));"}
          onClick={logout}
        />
        <HelpButton />
      </Stack>
      {children}
    </Box>
  );
};

export default Container;
