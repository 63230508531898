import useSearchFilters from "@/hooks/useSearchFilters";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  HStack,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  //  Wrap,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type SearchFiltersProps = {
  onClose: () => void;
  isOpen: boolean;
};

/* const years = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2015",
  "2010",
  "2000",
  "1990",
]; */

const SearchFilters = (props: SearchFiltersProps) => {
  const { onClose, isOpen } = props;
  const { t } = useTranslation();
  const {
    year,
    setYear,
    fieldsOfStudy,
    setFieldsOfStudy,
    minCitations,
    setMinCitations,
    influentialCitationFilter,
    setInfluentialCitationFilter,
    isOpenAccess,
    setIsOpenAccess,
    reset,
  } = useSearchFilters();
  const [params] = useSearchParams();

  const isAiSearch = params.get("ai-search") === "true";

  useEffect(() => {
    const fields = params.get("fieldsOfStudy");
    if (fields) {
      setFieldsOfStudy(decodeURIComponent(fields).split(","));
    }
    const minYear = params.get("min_year");
    const maxYear = params.get("max_year");
    if (minYear && maxYear) {
      setYear(minYear, maxYear);
    }

    const minCitations = params.get("minCitations");
    if (minCitations) {
      setMinCitations(parseInt(minCitations));
    }

    const isOpenAccess = params.get("isOpenAccess");
    if (isOpenAccess) {
      setIsOpenAccess(isOpenAccess === "true");
    }

    const influential = params.get("influential");
    if (influential) {
      setInfluentialCitationFilter(influential === "true");
    }
  }, [
    params,
    setFieldsOfStudy,
    setYear,
    setMinCitations,
    setInfluentialCitationFilter,
    setIsOpenAccess,
  ]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} placement={"right"}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading as={"h2"} size={"md"}>
            {t("search.filters.title")}
          </Heading>
        </DrawerHeader>
        <DrawerBody>
          <FormControl>
            <FormLabel>{t("search.filters.yearRange")}</FormLabel>
            <HStack gap={4}>
              <Box w={"6ch"}>{year?.min || 1900}</Box>

              <RangeSlider
                aria-label={["min", "max"]}
                colorScheme="brand"
                onChange={(values) => {
                  setYear(values[0].toString(), values[1].toString());
                }}
                defaultValue={[
                  year?.min ? parseInt(year.min) : 1900,
                  year?.max ? parseInt(year.max) : new Date().getFullYear(),
                ]}
                min={1900}
                max={new Date().getFullYear()}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} />
                <RangeSliderThumb index={1} />
              </RangeSlider>
              <Box w={"6ch"}>{year?.max || new Date().getFullYear()}</Box>
            </HStack>
          </FormControl>
          {/* <input
            type="number"
            name="max_year"
            value={maxYear}
            onChange={(e) => setMaxYear(e.target.value)}
            hidden
           
          />

          <FormLabel mb={4}>{t("search.filters.maxYear")}</FormLabel>
          <Wrap spacing={2} justifyContent={"space-around"}>
            <Button
              flexGrow={1}
              onClick={() => setMaxYear("")}
              colorScheme={maxYear === "" ? "brand" : "gray"}
            >
              {t("search.filters.any")}
            </Button>
            {years.map((year) => (
              <Button
                flexGrow={1}
                key={year}
                onClick={() => setMaxYear(year)}
                colorScheme={maxYear === year ? "brand" : "gray"}
              >
                {year}
              </Button>
            ))}
          </Wrap> */}
          <FormControl display="flex" alignItems="center" my={8} gap={2}>
            <Checkbox
              inputProps={{
                "aria-label": "Is Open Access",
              }}
              isChecked={isOpenAccess}
              onChange={() => setIsOpenAccess(!isOpenAccess)}
              colorScheme="brand"
              id="isOpenAccess"
              name="isOpenAccess"
              value={"true"}
            />
            <FormLabel mb={0} htmlFor="isOpenAccess">
              {t("search.filters.isOpenAccess")}
            </FormLabel>
          </FormControl>
          {isAiSearch ? (
            <FormControl display="flex" alignItems="center" my={8} gap={2}>
              <Checkbox
                inputProps={{
                  "aria-label": "Influential Only",
                }}
                isChecked={influentialCitationFilter}
                onChange={() =>
                  setInfluentialCitationFilter(!influentialCitationFilter)
                }
                colorScheme="brand"
                id="influentialCitationFilter"
                name="influentialCitationFilter"
                value={"true"}
              />
              <FormLabel mb={0} htmlFor="influentialCitationFilter">
                {t("search.filters.influentialCitationFilter")}
              </FormLabel>
            </FormControl>
          ) : (
            <FormControl>
              <FormLabel htmlFor="minCitations">
                {t("search.filters.minCitations")}
              </FormLabel>
              <NumberInput
                value={minCitations}
                onChange={(val) => setMinCitations(parseInt(val))}
                min={0}
              >
                <NumberInputField name="minCitations" id="minCitations" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          )}

          <FormControl mt={8}>
            <FormLabel>{t("search.filters.fields.title")}</FormLabel>
            <Checkbox
              mb={4}
              name="fieldOfStudy"
              value="all"
              onChange={() => setFieldsOfStudy([])}
              colorScheme="brand"
              isChecked={fieldsOfStudy.length == 0}
            >
              {t("search.filters.fields.all")}
            </Checkbox>
            <CheckboxGroup
              onChange={(val) => setFieldsOfStudy(val as string[])}
              value={fieldsOfStudy}
              colorScheme="brand"
            >
              <Stack spacing={4} direction={["column"]}>
                <Checkbox name="fieldOfStudy" value={"Medicine"}>
                  {t("search.filters.fields.medicine")}
                </Checkbox>
                <Checkbox name="fieldOfStudy" value={"Computer Science"}>
                  {t("search.filters.fields.cs")}
                </Checkbox>
                <Checkbox value={"Chemistry"}>
                  {t("search.filters.fields.chemistry")}
                </Checkbox>
                <Checkbox value={"Biology"}>
                  {t("search.filters.fields.biology")}
                </Checkbox>
                <Checkbox value={"Materials Science"}>
                  {t("search.filters.fields.materials")}
                </Checkbox>
                <Checkbox value={"Physics"}>
                  {t("search.filters.fields.physics")}
                </Checkbox>
                <Checkbox value={"Geology"}>
                  {t("search.filters.fields.geology")}
                </Checkbox>
                <Checkbox value={"Psychology"}>
                  {t("search.filters.fields.psychology")}
                </Checkbox>
                <Checkbox value={"Art"}>
                  {t("search.filters.fields.art")}
                </Checkbox>
                <Checkbox value={"History"}>
                  {t("search.filters.fields.history")}
                </Checkbox>
                <Checkbox value={"Geography"}>
                  {t("search.filters.fields.geography")}
                </Checkbox>
                <Checkbox value={"Sociology"}>
                  {t("search.filters.fields.sociology")}
                </Checkbox>
                <Checkbox value={"Business"}>
                  {t("search.filters.fields.business")}
                </Checkbox>
                <Checkbox value={"Political Science"}>
                  {t("search.filters.fields.political")}
                </Checkbox>
                <Checkbox value={"Economics"}>
                  {t("search.filters.fields.economics")}
                </Checkbox>
                <Checkbox value={"Philosophy"}>
                  {t("search.filters.fields.philosophy")}
                </Checkbox>
                <Checkbox value={"Mathematics"}>
                  {t("search.filters.fields.math")}
                </Checkbox>
                <Checkbox value={"Engineering"}>
                  {t("search.filters.fields.engineering")}
                </Checkbox>
                <Checkbox value={"Environmental Science"}>
                  {t("search.filters.fields.environmental")}
                </Checkbox>
                <Checkbox value={"Agricultural and Food Sciences"}>
                  {t("search.filters.fields.agricultural")}
                </Checkbox>
                <Checkbox value={"Education"}>
                  {t("search.filters.fields.education")}
                </Checkbox>
                <Checkbox value={"Law"}>
                  {t("search.filters.fields.law")}
                </Checkbox>
                <Checkbox value={"Linguistics"}>
                  {t("search.filters.fields.linguistics")}
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </DrawerBody>
        <DrawerFooter gap={4}>
          <Button onClick={reset}>{t("search.filters.reset")}</Button>
          <Button
            type="submit"
            onClick={onClose}
            form="search-form"
            colorScheme="brand"
          >
            {t("search.filters.submit")}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SearchFilters;
