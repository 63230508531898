import { SearchResponseItem } from "@/lib/SemanticScholarApi";
// @ts-expect-error citation-js is not typed
import Cite from "citation-js";
import "@citation-js/plugin-ris";
import slugify from "slugify";
import { useTranslation } from "react-i18next";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Icon,
  VStack,
  Text,
} from "@chakra-ui/react";
import { MdOutlineFileDownload, MdDownload } from "react-icons/md";

type ExportMenuProps = {
  papers: Array<SearchResponseItem>;
  title: string;
  disabled?: boolean;
};
const ExportMenu = ({ papers, title, disabled = false }: ExportMenuProps) => {
  const { t } = useTranslation();

  const exportCollection = () => {
    const bibliography = new Cite();
    papers.forEach((paper) => {
      const id = paper.citationStyles?.bibtex || paper.externalIds.DOI;
      if (!id) return;
      const citation = new Cite(id);

      bibliography.add({
        ...citation.get()[0],
        URL: `https://my.paperdoc-app.com/article/${slugify(paper.title, {
          lower: true,
          strict: true,
        })}/${paper.corpusId}`,
        title: paper.title,
      });
    });

    /*  searches.forEach((search) => {
          bibliography.add({
            type: "webpage",
            title: search.query,
            URL: `https://my.paperdoc-app.com/search?q=${
              search.query
            }&token=${search.token}&ai-search=true&insights=true&summary=true`,
          });
        }); */

    const output = bibliography.format("ris");

    const blob = new Blob([output], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.ris`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const exportCollectionToCsv = () => {
    const csv = [
      ["Title", "Authors", "Journal", "Year", "Citation Count", "DOI", "URL"],
    ];
    papers.forEach((paper) => {
      csv.push([
        `"${paper.title}"`,
        `"${paper.authors.map((a) => a.name).join(", ")}"`,
        paper.publicationVenue?.name || " ",
        paper.year.toString(),
        paper.citationCount.toString(),
        paper.externalIds.DOI || " ",
        `https://my.paperdoc-app.com/article/${slugify(paper.title, {
          lower: true,
          strict: true,
        })}/${paper.corpusId}`,
      ]);
    });

    const csvContent = csv.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${title}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Menu>
      <MenuButton
        isDisabled={disabled}
        as={IconButton}
        icon={<Icon as={MdOutlineFileDownload} w={6} h={6} />}
      />
      <MenuList color={"MenuText"}>
        <MenuItem
          onClick={exportCollection}
          icon={<Icon as={MdDownload} w={6} h={6} />}
        >
          <VStack alignItems={"flex-start"}>
            <Text>{t("collections.ris")}</Text>
            <Text fontSize="xs">{t("collections.risDesc")}</Text>
          </VStack>
        </MenuItem>
        <MenuItem
          onClick={exportCollectionToCsv}
          icon={<Icon as={MdDownload} w={6} h={6} />}
        >
          <VStack alignItems={"flex-start"}>
            <Text>{t("collections.csv")}</Text>
            <Text fontSize="xs">{t("collections.csvDesc")}</Text>
          </VStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExportMenu;
