import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#E6F2F0",
      500: "#00BCB0",
      900: "#00B2AA",
    },
    black: {
      100: "#282D1F",
      500: "#1A1E13",
      900: "#1A1E13",
    },
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          _focus: {
            outlineColor: "brand.500",
            outlineOffset: 0,
          },
        },
      },
      variants: {
        auth: {
          field: {
            bg: "white",
            borderRadius: 25,

            color: "black.500",
            _placeholder: {
              color: "black.100",
            },
            _disabled: {
              bg: "gray.200",
              opacity: 1,
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        auth: {
          bg: "white",
          borderRadius: 25,
          _focus: {
            outlineColor: "brand.500",
            outlineOffset: 0,
          },
          color: "black.500",
          _placeholder: {
            color: "black.100",
          },
          _disabled: {
            bg: "gray.200",
            opacity: 1,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 25,
        px: 2,
        py: 1,
      },

      variants: {
        primary: {
          bg: "brand.500",
          color: "white",
          _hover: {
            bg: "brand.900",
          },
        },
        black: {
          bg: "black.500",
          color: "white",
          _hover: {
            bg: "black.900",
          },
        },
      },
    },
    Progress: {
      baseStyle: {
        track: {
          innerHeight: 15,
          borderRadius: 25,
          p: 1,
        },
        filledTrack: {
          borderRadius: 25,
        },
      },
      defaultProps: {
        colorScheme: "brand",
        size: "lg",
      },
      variants: {
        yes: {
          filledTrack: {
            bg: "green.500",
            borderRadius: 25,
          },
          track: {
            borderRadius: 25,
            p: 0,
          },
        },
        potentially: {
          filledTrack: {
            bg: "yellow.500",
            borderRadius: 25,
          },
          track: {
            borderRadius: 25,
            p: 0,
          },
        },
        no: {
          filledTrack: {
            bg: "red.500",
            borderRadius: 25,
          },
          track: {
            borderRadius: 25,
            p: 0,
          },
        },
      },
    },
  },
  fonts: {
    body: "Be Vietnam Pro",
    heading: "Excon",
  },
});

export default theme;
