import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  ListItem,
  SkeletonText,
  Tag,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";
import {
  getArchieQQuestions,
  getArchieQAnswer,
} from "@/lib/cloudFunctions/ArchieQ";
import ArchieQAnswer from "./ArchieQ/ArchieQAnswer";

const ArchieQ = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {
    archieQ,

    setArchieData,
    setRefs: setArchieRefs,
    token,
  } = useArchieData((state) => state);

  const loadAnswer = useCallback(
    async (index: number, data: typeof archieQ) => {
      if (index < 0) return;
      if (!data) return;

      if (data.answers[index]) {
        return;
      }
      await getArchieQAnswer({
        token,
        question: data.questions[index],
        question_index: index,
      });
    },
    [token]
  );

  useEffect(() => {
    if (token) {
      const unsub = onSnapshot(doc(dbIa, "archie_q", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          let sourceCount = 1;
          const answers = data.answers.map((answer: string | null) => {
            if (answer === null) {
              return null;
            }
            const { parts, currentSource } = parseText(answer, sourceCount);

            sourceCount = currentSource;
            return parts;
          });

          setArchieData({
            archieQ: {
              answers: answers,
              papers: data.papers,
              questions: data.questions,
            },
          });

          setArchieRefs(new Set(data.papers));
          setLoading(false);
        } else {
          const runArchie = async () => {
            try {
              setError(false);
              const response = await getArchieQQuestions({
                token,
              });

              loadAnswer(0, {
                questions: response.data.questions,
                answers: Array(response.data.questions.length).fill(null),
                papers: [],
              });
            } catch (error) {
              setLoading(false);
              setError(true);
            }
          };
          runArchie();
        }
      });
      return () => unsub();
    }
  }, [token, setArchieData, loadAnswer, setArchieRefs]);

  if (loading) return <SkeletonText w={"100%"} noOfLines={2} spacing={4} />;
  if (error)
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={4}
        marginY={"auto"}
        minW={"400px"}
      >
        <Text> {t("archie.error.title")}</Text>
        <UnorderedList>
          <ListItem>{t("archie.error.0")}</ListItem>
          <ListItem>{t("archie.error.2")}</ListItem>
          <ListItem>{t("archie.error.3")}</ListItem>
        </UnorderedList>

        <Text>{t("archie.error.final")}</Text>
      </Box>
    );
  if (!archieQ) return null;

  return (
    <Box>
      {archieQ?.papers.length > 0 && (
        <Tag mt={1}>
          {t("archie.papersAnalysed", {
            count: archieQ.papers.length,
          })}
        </Tag>
      )}

      <HStack gap={4} flexWrap={"wrap"} mt={4}></HStack>

      <Accordion
        defaultIndex={0}
        allowToggle
        onChange={(index) => {
          loadAnswer(parseInt(index.toString()), archieQ);
        }}
      >
        <AccordionItem>
          <AccordionButton
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={4}
          >
            <Text
              textAlign={"left"}
              sx={{
                textWrap: "balance",
              }}
              fontWeight={"bold"}
            >
              {archieQ.questions[0]}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            {archieQ.answers[0] ? (
              <ArchieQAnswer
                answer={archieQ.answers[0]}
                question={archieQ.questions[0]}
              />
            ) : (
              <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />
            )}
          </AccordionPanel>
        </AccordionItem>
        <Text fontWeight={"bold"} as={"h3"} size={"md"} my={4}>
          {t("archie.additional_questions")}
        </Text>
        {archieQ.questions.slice(1).map((question, index) => (
          <AccordionItem key={index + 1}>
            <AccordionButton
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={4}
            >
              <Text
                textAlign={"left"}
                sx={{
                  textWrap: "balance",
                }}
                fontWeight={"bold"}
              >
                {question}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {archieQ.answers[index + 1] ? (
                <ArchieQAnswer
                  answer={archieQ.answers[index + 1]}
                  question={question}
                />
              ) : (
                <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default ArchieQ;
