import { useUserSession } from "@/hooks/useUserSession";
import AuthContainer from "@/ui/Auth/AuthContainer";
import { Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

const Register = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);
  return (
    <>
      {" "}
      <AuthContainer>
        <Heading mb={8} color={"#fff"}>
          {t("auth.register.subtitle.base")}
        </Heading>

        <Outlet />
      </AuthContainer>
    </>
  );
};

export default Register;
