import useRegistrationForm from "@/hooks/useRegistrationForm";
import { authIa, dbIa } from "@/lib/firebase";

import {
  Button,
  Link as ChakraLink,
  Box,
  Progress,
  Checkbox,
  useToast,
  Text,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type FormData = {
  cgv: boolean;
  newsletter: boolean;
};

const Terms = () => {
  const { t } = useTranslation();
  const {
    setCgv,
    setNewsletter,
    firstName,
    lastName,
    country,
    zip,
    specialty,
    status,
    domain,
    email,
    password,
    reset,
    rppsData,
  } = useRegistrationForm();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setCgv(data.cgv);
      setNewsletter(data.newsletter);
      const { user } = await createUserWithEmailAndPassword(
        authIa,
        email,
        password
      );
      await setDoc(doc(dbIa, "users", user.email as string), {
        firstName,
        lastName,
        country,
        zip,
        specialty,
        status,
        domain,
        email,
        cgv: data.cgv,
        newsletter: data.newsletter,
        createdAt: new Date(),
        liked: [],
        rpps: rppsData?.rpps || null,
      });
      useRegistrationForm.persist.clearStorage();
      reset();
      toast({
        title: t("auth.register.accountCreated"),
        description: t("auth.register.accountCreatedDescription"),
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/");
    } catch (error) {
      setError("root", {
        type: "manual",
        message: t("auth.register.error"),
      });
    }
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      <Text mb={-6} textAlign={"center"}>
        {t("auth.register.winCoins")}
      </Text>
      <Progress value={100} />
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}

      <Checkbox colorScheme="brand" required {...register("cgv")}>
        <Trans t={t} key={"auth.register.acceptCgv"}>
          J'ai lu et j'accepte les{" "}
          <ChakraLink
            textDecoration={"underline"}
            target="_blank"
            href="https://paperdoc-app.com/fr/privacy-policy"
          >
            conditions générales d'utilisation
          </ChakraLink>
        </Trans>
      </Checkbox>
      <Checkbox colorScheme="brand" {...register("newsletter")}>
        {t("auth.register.newsletter")}
      </Checkbox>
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.createAccount")}
      </Button>
    </Box>
  );
};

export default Terms;
