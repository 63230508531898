import { RefObject } from "react";
import { useUserSession } from "./useUserSession";
import useSearchFilters from "./useSearchFilters";
import { increment, updateDoc } from "firebase/firestore";
import { useSubmit } from "react-router-dom";

const useSearchSubmit = (formRef: RefObject<HTMLFormElement>) => {
  const { user } = useUserSession();
  const {
    fieldsOfStudy,
    year,
    influentialCitationFilter,
    minCitations,
    isOpenAccess,
  } = useSearchFilters();

  const submit = useSubmit();

  const handleSubmit = (isAiRedirect?: boolean) => {
    const values = new FormData(formRef.current as HTMLFormElement);
    const usedValues: [string, string][] = [];
    if (!values.get("q")) {
      return;
    }

    values.forEach((value, key) => {
      if (value) {
        usedValues.push([key, value.toString()]);
      }
    });

    if (isAiRedirect) {
      usedValues.push(["ai-search", "true"]);
    }

    if (fieldsOfStudy && fieldsOfStudy.length > 0) {
      usedValues.push(["fieldsOfStudy", fieldsOfStudy.join(",")]);
    }

    if (year) {
      usedValues.push(["min_year", year.min]);
      usedValues.push(["max_year", year.max]);
    }

    if (minCitations) {
      usedValues.push(["minCitations", minCitations.toString()]);
    }

    if (influentialCitationFilter) {
      usedValues.push(["influential", influentialCitationFilter.toString()]);
    }

    if (isOpenAccess) {
      usedValues.push(["isOpenAccess", isOpenAccess.toString()]);
    }

    if (user) {
      updateDoc(user.ref, {
        searchesCount: increment(1),
      });
    }

    const searchParams = new URLSearchParams([...usedValues]);

    submit(searchParams, {
      action: "/search",
      method: "get",
    });
  };

  return handleSubmit;
};

export default useSearchSubmit;
