import useArchieData from "@/hooks/useArchieData";
import SearchArticle from "@/ui/SearchArticle";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SearchResults = () => {
  const { t } = useTranslation();
  const { filterByAnswer, papers, archieYO, selectedAnswer, displayed, refs } =
    useArchieData();

  const filteredArticles = useMemo(() => {
    if (!archieYO || !filterByAnswer) {
      return [];
    }

    const selectedAnswers = Object.entries(selectedAnswer).flatMap(
      ([key, value]) => {
        return value ? key : [];
      }
    ) as ("yes" | "no" | "potentially" | "irrelevant_paper")[];

    return papers.filter((paper) => {
      return selectedAnswers.includes(archieYO.answers[paper.corpusId]?.answer);
    });
  }, [filterByAnswer, selectedAnswer, archieYO, papers]);

  const additionnalResults = useMemo(() => {
    return Object.entries(papers).flatMap(([key, value]) => {
      if (!refs.has(value.corpusId.toString()))
        return <SearchArticle key={key} {...value} hideArchieAnswer />;
      return [];
    });
  }, [papers, refs]);

  return (
    <Box w={"100%"}>
      {displayed.insights || displayed.summary ? (
        <Accordion allowMultiple defaultIndex={[0]}>
          <AccordionItem w={"100%"} borderWidth={0} mb={4}>
            {({ isExpanded }) => (
              <>
                <AccordionButton justifyContent={"flex-start"} gap={4}>
                  {isExpanded ? (
                    <MinusIcon fontSize={"lg"} />
                  ) : (
                    <AddIcon fontSize={"lg"} />
                  )}

                  <Heading as={"h2"} fontWeight={"bold"} fontSize={"2xl"}>
                    {t("search.results.archie")}
                  </Heading>
                </AccordionButton>

                <AccordionPanel
                  gap={8}
                  p={2}
                  pt={10}
                  display={"flex"}
                  flexDirection={"column"}
                  pb={4}
                >
                  {refs.size === 0 && (
                    <Skeleton
                      w={"100%"}
                      h={"100px"}
                      bgColor={"#fff"}
                      borderRadius={28}
                      p={2}
                    />
                  )}
                  {filterByAnswer
                    ? filteredArticles.map((document) => (
                        <SearchArticle key={document.paperId} {...document} />
                      ))
                    : [...refs].flatMap((corpusId) => {
                        const paper = papers.find(
                          (paper) => paper.corpusId === parseInt(corpusId)
                        );
                        if (!paper) return [];
                        return <SearchArticle key={corpusId} {...paper} />;
                      })}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem
            pt={4}
            sx={{
              "&:last-child": {
                borderBottomWidth: 0,
              },
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton justifyContent={"flex-start"} gap={4}>
                  {isExpanded ? (
                    <MinusIcon fontSize={"lg"} />
                  ) : (
                    <AddIcon fontSize={"lg"} />
                  )}
                  <Heading as={"h2"} fontWeight={"bold"} fontSize={"2xl"}>
                    {t("search.results.additional")}
                  </Heading>
                </AccordionButton>

                <AccordionPanel
                  gap={8}
                  pt={10}
                  p={2}
                  display={"flex"}
                  flexDirection={"column"}
                  pb={4}
                >
                  {additionnalResults}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ) : (
        <Box gap={8} p={2} display={"flex"} flexDirection={"column"} pb={4}>
          {Object.entries(papers).map(([key, value]) => {
            return <SearchArticle key={key} {...value} hideArchieAnswer />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default SearchResults;
