import { useCollections } from "@/hooks/useCollections";
import { Collection } from "@/lib/types/Collection";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  FormErrorMessage,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdDelete, MdEdit, MdOutlineMoreVert } from "react-icons/md";

type CollectionMenuProps = {
  collection: Collection;
  iconColor?: string;
};
type RenameCollectionFormData = {
  name: string;
};

const CollectionMenu = ({
  collection,
  iconColor = "#000",
}: CollectionMenuProps) => {
  const { t } = useTranslation();
  const { deleteCollection, renameCollection } = useCollections();
  const {
    isOpen: isDeleteCollectionOpen,
    onOpen: openDeleteCollection,
    onClose: closeDeleteCollection,
  } = useDisclosure();

  const {
    isOpen: isCollectionRenameOpen,
    onOpen: openCollectionRename,
    onClose: closeCollectionRename,
  } = useDisclosure();
  const cancelDeletionRef = useRef<HTMLButtonElement>(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<RenameCollectionFormData>({
    defaultValues: {
      name: collection.name,
    },
  });

  const handleDeleteCollection = async () => {
    await deleteCollection(collection.id);

    closeDeleteCollection();
  };

  const handleRenameCollection = handleSubmit(async ({ name }) => {
    await renameCollection(collection.id, name);

    closeCollectionRename();
  });
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={
            <Icon
              as={MdOutlineMoreVert}
              aria-label={t("collections.option")}
              w={6}
              h={6}
              color={iconColor}
            />
          }
          colorScheme="ghost"
        />
        <MenuList color={"MenuText"}>
          <MenuItem
            icon={<Icon as={MdEdit} w={4} h={4} />}
            onClick={() => {
              openCollectionRename();
            }}
          >
            {t("collections.rename.rename")}
          </MenuItem>
          <MenuItem
            icon={<Icon as={MdDelete} w={4} h={4} />}
            onClick={() => {
              openDeleteCollection();
            }}
          >
            {t("collections.delete.delete")}
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isCentered
        isOpen={isDeleteCollectionOpen}
        leastDestructiveRef={cancelDeletionRef}
        onClose={closeDeleteCollection}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor={"#F2F6F9"}>
            <AlertDialogHeader>
              {t("collections.delete.title")}
            </AlertDialogHeader>
            <AlertDialogBody>{t("collections.delete.confirm")}</AlertDialogBody>
            <AlertDialogFooter gap={4}>
              <Button ref={cancelDeletionRef} onClick={closeDeleteCollection}>
                {t("collections.delete.cancel")}
              </Button>
              <Button colorScheme="red" onClick={handleDeleteCollection}>
                {t("collections.delete.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isCollectionRenameOpen}
        onClose={closeCollectionRename}
        size={"xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bgColor={"#F2F6F9"}>
          <ModalHeader>{t("collections.rename.title")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleRenameCollection}>
            <ModalBody>
              <FormLabel>{t("collections.creation.name")}</FormLabel>
              <Input
                variant={"auth"}
                outline={"2px solid #F2F6F9"}
                {...register("name", { required: true, maxLength: 100 })}
                placeholder={t("collections.creation.name")}
                isInvalid={errors.name !== undefined}
              />
              {errors.name && (
                <FormErrorMessage>
                  {t("collections.creation.required")}
                </FormErrorMessage>
              )}
            </ModalBody>
            <ModalFooter gap={4}>
              <Button type="reset" onClick={closeCollectionRename}>
                {t("common.cancel")}
              </Button>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="brand"
              >
                {t("collections.rename.rename")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CollectionMenu;
