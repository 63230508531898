import { Box, BoxProps } from "@chakra-ui/react";

const PlanCard = (props: BoxProps) => {
  return (
    <Box
      bgColor={"white"}
      p={8}
      borderWidth={1}
      minW={300}
      borderColor={"brand.100"}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default PlanCard;
