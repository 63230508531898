import { Skeleton } from "@chakra-ui/react";

const ToolBoxLoading = () => {
  return Array.from({
    length: 10,
  }).map((_, index) => (
    <Skeleton
      w={"100%"}
      h={"100px"}
      bgColor={"#fff"}
      borderRadius={24}
      p={2}
      key={index}
    />
  ));
};

export default ToolBoxLoading;
