import Tools from "@/components/Tools";
import { Tool } from "@/lib/types";
import Container from "@/ui/Container";
import MainCard from "@/ui/MainCard";
import ToolBoxLoading from "@/ui/pending/ToolboxLoading";
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Await, useLoaderData } from "react-router-dom";

type Categories = {
  category: string;
  tools: Tool[];
}[];

const ToolBox = () => {
  const { standAloneTools, categories } = useLoaderData() as {
    standAloneTools: Tool[];
    categories: Promise<Categories>;
  };
  const { t } = useTranslation();

  return (
    <Container>
      <MainCard
        mt={8}
        bgImage={"/backgrounds/logo-tools.svg"}
        bgRepeat={"no-repeat"}
        bgAttachment={"scroll"}
        bgSize={"cover"}
        display={"flex"}
        flexDirection={"column"}
        w={"100%"}
      >
        <Heading
          color={"#fff"}
          as={"h1"}
          size={"xl"}
          textAlign={"center"}
          mt={8}
          mb={4}
        >
          {t("sidebar.toolbox")}
        </Heading>
        <Text color={"#fff"} textAlign={"center"} fontStyle={"italic"} mb={8}>
          {t("toolbox.subtitle")}
        </Text>
        <Box gap={4} pb={30}>
          <SimpleGrid columns={[1, 2]} spacing={4} as={motion.div} layoutRoot>
            <Suspense fallback={<ToolBoxLoading />}>
              <Await resolve={categories}>
                {(categories: Categories) => (
                  <>
                    <Tools
                      title={t("toolbox.standalone")}
                      tools={standAloneTools}
                    />
                    {categories
                      .sort((a, b) => a.category.localeCompare(b.category))
                      .map((category) => (
                        <Tools
                          key={category.category}
                          title={category.category}
                          tools={category.tools}
                        />
                      ))}
                  </>
                )}
              </Await>
            </Suspense>
          </SimpleGrid>
        </Box>
      </MainCard>
    </Container>
  );
};

export default ToolBox;
