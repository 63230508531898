import {
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdCollectionsBookmark } from "react-icons/md";
import { Link } from "react-router-dom";
import AddElementToCollectionModal from "./AddElementToCollectionModal";

type CollectionSearchProps = {
  search: {
    token: string;
    query: string;
  };
};
const CollectionSearch = ({ search }: CollectionSearchProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <LinkBox
        minW={300}
        w={"100%"}
        key={search.token}
        bgColor={"white"}
        borderRadius={12}
        p={4}
        orientation="horizontal"
        as={Card}
      >
        <CardBody padding={0}>
          <HStack justifyContent={"space-between"}>
            <LinkOverlay
              as={Link}
              to={`/search?q=${search.query}&token=${search.token}&ai-search=true&insights=true&summary=true`}
            >
              <Text fontWeight={"bold"}>{search.query}</Text>
            </LinkOverlay>
            <IconButton
              onClick={onOpen}
              variant={"ghost"}
              aria-label="Add to collection"
              icon={<Icon as={MdCollectionsBookmark} w={6} h={6} />}
            />
          </HStack>
        </CardBody>
      </LinkBox>
      <AddElementToCollectionModal
        isOpen={isOpen}
        onClose={onClose}
        elementId={search.token}
        elementType="search"
      />
    </>
  );
};

export default CollectionSearch;
