import { useNavigate, useRouteError } from "react-router-dom";
import Container from "./Container";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import MainCard from "./MainCard";
import { Wrap, Button, Heading, Text } from "@chakra-ui/react";
const AppError = () => {
  const error = useRouteError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const isDev = import.meta.env.DEV;
      if (isDev) {
        console.error(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }, [error]);
  return (
    <Container>
      <MainCard
        bgImage={"/backgrounds/logo-error.svg"}
        bgSize={"cover"}
        p={[4, 12]}
        display={"flex"}
        flexDirection={"column"}
        bgAttachment={"scroll"}
        bgPosition={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={10}
        w={"100%"}
      >
        <Heading as={"h1"} size={"xl"} textAlign={"center"}>
          {t("error.title")}
        </Heading>
        <Text textAlign={"center"}>{t("error.message")}</Text>
        <Wrap gap={4} justifyContent="center">
          <Button onClick={() => window.location.reload()}>
            {t("error.reload")}
          </Button>
          <Button onClick={() => navigate("/")}>{t("error.back")}</Button>
        </Wrap>
      </MainCard>
    </Container>
  );
};

export default AppError;
