import {
  Box,
  Heading,
  HStack,
  SkeletonText,
  Tag,
  Text,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";
import { useCallback, useEffect, useRef, useState } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";
import { runArchieSumm } from "@/lib/cloudFunctions/ArchieSumm";
import { useTranslation } from "react-i18next";

import CopyContent from "./CopyContent";
const ArchieSumm = () => {
  const { archieSUMM, setArchieData, token, setRefs } = useArchieData(
    (state) => state
  );
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const fetchArchieSumm = useCallback(async () => {
    setLoading(true);
    await runArchieSumm({
      token,
    });

    setLoading(false);
  }, [token]);

  useEffect(() => {
    if (token) {
      const unsub = onSnapshot(doc(dbIa, "archie_summ", token), (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setRefs(new Set(data.papers));
          setArchieData({
            archieSUMM: {
              papers: data.papers,
              summary: data.summary,
            },
          });
          setLoading(false);
        } else {
          fetchArchieSumm();
        }
      });
      return () => unsub();
    }
  }, [setRefs, setArchieData, fetchArchieSumm, token]);

  if (loading) return <SkeletonText noOfLines={2} spacing={4} mb={4} />;
  if (!archieSUMM) return null;
  let sourceCount = 1;

  return (
    <Box>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Tag mt={1}>
          {t("archie.papersAnalysed", {
            count: archieSUMM.papers.length,
          })}
        </Tag>
        <CopyContent contentRef={contentRef} refs={archieSUMM.papers} />
      </HStack>

      <Box ref={contentRef} flexGrow={1} mb={4}>
        {archieSUMM.summary.sections.map((section, index) => {
          const { parts, currentSource } = parseText(
            section.content,

            sourceCount
          );
          sourceCount = currentSource;

          return (
            <div key={index}>
              <Heading as={"h4"} size={"md"} my={4}>
                {section.section_title}
              </Heading>
              {section.content ? (
                <Text>{parts.map((e) => e)}</Text>
              ) : (
                <>
                  {section.sub_sections.map((sub_section, sub_index) => {
                    const { parts, currentSource } = parseText(
                      sub_section.content,

                      sourceCount
                    );
                    sourceCount = currentSource;
                    return (
                      <div key={sub_index}>
                        <Heading as={"h5"} size={"sm"} my={3}>
                          {sub_section.sub_section_title}
                        </Heading>
                        <Text>{parts.map((e) => e)}</Text>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ArchieSumm;
