import semanticScholarApi from "@/lib/SemanticScholarApi";

import useSummaryGeneration from "@/lib/hooks/useSummaryGeneration";
import Beta from "@/ui/Beta";
import Container from "@/ui/Container";
import MainCard from "@/ui/MainCard";
import ProgressBar from "@/ui/ia/ProgressBar";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Heading,
  HStack,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { DocumentReference, getDoc } from "firebase/firestore";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import slugify from "slugify";

const Ia = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      setError("");
      startSummary();
    }
  }, [file]);
  const {
    isOpen: isBetaOpen,
    onClose: onBetaClose,
    onOpen: onBetaOpen,
  } = useDisclosure();

  const handleBetaClose = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const dontShow = e.currentTarget.querySelector<HTMLInputElement>(
      "input[type=checkbox]"
    )?.checked;

    if (dontShow) {
      localStorage.setItem("paperdoc-ai-beta-hidden", "1");
    }

    onBetaClose();
  };

  useEffect(() => {
    const shouldShow = !localStorage.getItem("paperdoc-ai-beta-hidden");
    if (shouldShow) {
      onBetaOpen();
    }
  }, [onBetaOpen]);

  const onSummaryGenerated = async (full_summary: DocumentReference) => {
    const fullSummary = await getDoc(full_summary);
    if (fullSummary.exists() === false) {
      setError(t("summarize.error"));
      return;
    }

    const data = fullSummary.data() as { associated_corpusid: string };
    const corpusId = data.associated_corpusid;

    const res = await semanticScholarApi.search.get(
      `/paper/CorpusId:${corpusId}`,
      {
        params: {
          fields: "title",
        },
      }
    );

    const slug = slugify(res.data.title, {
      lower: true,
      strict: true,
    });
    setSummaryDoc({
      id: corpusId,
      slug,
    });
  };

  const {
    currentStep,

    loading,
    error,
    setError,
    summaryDoc,
    setSummaryDoc,
    reset,
    startUnknownSummary,
  } = useSummaryGeneration(onSummaryGenerated);

  const startSummary = async () => {
    if (!file) return setError(t("summarize.fileError"));
    startUnknownSummary(file);
  };

  return (
    <Container p={[2, 8]}>
      <MainCard
        mt={8}
        bgImage={"/backgrounds/logo-ia.svg"}
        bgRepeat={"no-repeat"}
        bgSize={"cover"}
        display={"flex"}
        flexDirection={"column"}
        w={"100%"}
        bgAttachment={"scroll"}
      >
        <HStack mt={8} justifyContent={"center"} alignSelf={"center"}>
          <Heading color={"#fff"} as={"h1"} size={"xl"} textAlign={"center"}>
            {t("summarize.title")}
          </Heading>
          <Beta onClick={onBetaOpen} />
        </HStack>

        <Text mb={4} color={"#fff"} textAlign={"center"} fontSize={"1.5rem"}>
          {t("summarize.subtitle")}
        </Text>
        <OrderedList
          display={"flex"}
          flexDirection={"column"}
          color={"#FFF"}
          mb={4}
          fontSize={"1.2rem"}
          alignSelf={"center"}
          transform={["none", "none", "translateX(20%)"]}
        >
          <ListItem>{t("summarize.steps.pdf")}</ListItem>
          <ListItem>{t("summarize.steps.process")}</ListItem>
          <ListItem>{t("summarize.steps.format")}</ListItem>
        </OrderedList>
        <Box bgColor={"rgba(255,255,255, 0.1)"} borderRadius={12}>
          <ProgressBar step={currentStep} />
        </Box>
        {error && (
          <Box
            mt={4}
            color="white"
            fontSize="1.5rem"
            textAlign="center"
            bgColor={"tomato"}
            p={4}
            borderRadius={12}
          >
            {error}
          </Box>
        )}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDir={"column"}
          gap={8}
          color={"#fff"}
          p={[4, 8]}
        >
          {currentStep === 0 && (
            <>
              <Heading>{t("summarize.upload")}</Heading>
              <input
                type="file"
                accept="application/pdf"
                style={{ display: "none" }}
                disabled={loading}
                id="upload"
                onChange={(e) => {
                  if (e.target.files) {
                    setFile(e.target.files[0]);
                  }
                }}
              />
              <Button
                as="label"
                htmlFor="upload"
                colorScheme="brand"
                disabled={loading}
                cursor={"pointer"}
                borderRadius={12}
                rightIcon={<Image src={"/icons/upload.svg"} />}
              >
                {t("summarize.uploadLabel")}
              </Button>
              {file && (
                <Text
                  textAlign={"center"}
                  sx={{
                    textWrap: "balance",
                  }}
                >
                  {t("summarize.uploadedFile")} : <strong>{file.name}</strong>
                </Text>
              )}
              <Button
                onClick={startSummary}
                isDisabled={!file}
                isLoading={loading}
                borderRadius={12}
                sx={{
                  "&:disabled": {
                    bg: "gray.500",
                  },
                  color: "white",
                  bg: "black.500",
                }}
              >
                {t("summarize.summarize")}
              </Button>
            </>
          )}
          {currentStep === 1 && (
            <>
              <Heading as={"h2"}>{t("summarize.steps.processing")}</Heading>

              <CircularProgress isIndeterminate color="brand.500" />
              <Text fontSize={"1.5rem"}>{t("summarize.processing")}</Text>
              <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
            </>
          )}
          {currentStep === 2 && (
            <>
              <Heading as={"h2"}>{t("summarize.steps.formatting")}</Heading>
              <Text fontSize={"1.5rem"}>{t("summarize.formatting")}</Text>
              <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
            </>
          )}
          {currentStep === 3 && (
            <>
              <Heading as={"h2"}>{t("summarize.steps.done")}</Heading>
              <Text fontSize={"1.5rem"}>{t("summarize.done")}</Text>
              <Link
                to={{
                  pathname: `/article/${summaryDoc?.slug}/${summaryDoc?.id}`,
                  hash: "#summary",
                }}
              >
                <Button borderRadius={12} colorScheme={"brand"}>
                  {t("summarize.see")}
                </Button>
              </Link>
              <Button borderRadius={12} colorScheme="black" onClick={reset}>
                {t("summarize.another")}
              </Button>
            </>
          )}

          <Text
            as={"a"}
            href={"https://paperdoc-app.com/contact"}
            textDecoration={"underline"}
          >
            {t("summarize.contactUs")}
          </Text>
          <Text
            sx={{
              textWrap: "balance",
            }}
            textAlign={"center"}
            fontSize={"0.8rem"}
            color={"#fff"}
          >
            {t("summarize.requirements")}
          </Text>
        </Box>
      </MainCard>
      <Modal isOpen={isBetaOpen} onClose={onBetaClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("ai.beta.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            flexDir={"column"}
            gap={2}
            textAlign={"center"}
          >
            <Text>{t("ai.beta.body.title")}</Text>
            <UnorderedList textAlign={"left"}>
              <ListItem>{t("ai.beta.body.type")}</ListItem>
              <ListItem>{t("ai.beta.body.file")}</ListItem>

              <ListItem>{t("ai.beta.body.results")}</ListItem>
            </UnorderedList>
            <Text fontWeight={"bold"}>{t("ai.beta.body.conclusion")}</Text>
          </ModalBody>
          <ModalFooter gap={4} as="form" onSubmit={handleBetaClose}>
            <Checkbox value={"dontShow"}>{t("ai.beta.dontShow")}</Checkbox>
            <Button px={8} colorScheme="brand" type="submit">
              {t("ai.beta.accept")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Ia;
