import { httpsCallable } from "firebase/functions";

import { ArchieSubsequentRequest, ArchieSummResponse } from "@/lib/archie";
import { functions } from "@/lib/firebase";

const runArchieSumm = httpsCallable<
  ArchieSubsequentRequest,
  ArchieSummResponse
>(functions, "run_archie_summ");

export { runArchieSumm };
